import styled, { keyframes } from 'styled-components';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  height: calc(100vh - 7.5rem);
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--secondary-color);

  // STYLES MODAL - CPF
  .text-modal-cpf {
    font-size: 1.25rem;
    font-weight: 100;
  }

  .buttons-modal-cpf {
    width: 100%;

    display: flex;

    justify-content: space-between;
    align-items: end;

    margin-top: 6.25rem;

    button {
      width: 45%;
      height: 2.5rem;

      font-size: 1rem;
    }
  }
  // END STYLES MODAL - CPF
`;

export const ContentCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: #fff;

  // PEQUENO
  @media screen and (max-width: 1060px) {
    width: 100%;
    height: 100%;
  }

  // GRANDE
  @media screen and (min-width: 1060px) {
    width: 50%;
    height: 95%;
    max-height: 95%;

    box-shadow: 0px 0px 5px 2px rgba(200, 200, 200, 0.5);
    border: 1px solid var(--line-color);
    border-radius: 8px;
  }

  #headerContent {
    width: 100%;
    min-height: 7.5rem;
    max-height: 7.5rem;

    border-bottom: 0.5px solid var(--line-color);
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    button {
      width: 3.75rem;
      height: 3.75rem;

      border-radius: 50%;
      border: 1px solid var(--primary-color);
      background-color: var(--secondary-color);
    }

    p {
      text-align: center;
      font-size: 1rem;
      font-weight: 100;
    }
  }

  #listOptions {
    min-height: calc(100% - 7.5rem);
    max-height: calc(100% - 7.5rem);

    margin: 2rem 0 0 0;

    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 2rem;

    .row {
      width: 60%;

      display: flex;
      justify-content: space-between;
      align-items: center;

      border: none;
      background-color: transparent;

      height: 10%;

      a {
        text-decoration: none;
        color: #000;
      }

      div {
        display: flex;
        align-items: center;

        div {
          width: 2.5rem;
          height: 2.5rem;

          display: flex;
          justify-content: center;
          align-items: center;

          border: none;
          border-radius: 8px;
          box-shadow: -2px 2px 0 0 rgba(200, 200, 200, 0.5);

          background-color: var(--secondary-color);

          margin-right: 1rem;
        }
      }
    }
  }
`;
