import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  width: 25%;
  height: 2.5rem;

  border: 1px solid var(--line-color);
  border-radius: 8px;

  button {
    border: none;
    background: #fff;

    color: var(--primary-color);
  }

  p {
    font-size: 1.5rem;
  }
`;
