export const cpfCnpjValidation = (val: string): boolean => {
  if (val.length === 14) {
    let cpfStr = val.trim();

    cpfStr = cpfStr.replace(/\./g, '');
    cpfStr = cpfStr.replace('-', '');
    const cpf = cpfStr.split('');

    let v1 = 0;
    let v2 = 0;
    let aux = false;

    for (let i = 1; cpf.length > i; i += 1) {
      if (cpf[i - 1] !== cpf[i]) {
        aux = true;
      }
    }

    if (aux === false) {
      return false;
    }

    for (let i = 0, p = 10; cpf.length - 2 > i; i += 1, p -= 1) {
      v1 += Number(cpf[i]) * p;
    }

    v1 = (v1 * 10) % 11;

    if (v1 === 10) {
      v1 = 0;
    }

    if (v1 !== Number(cpf[9])) {
      return false;
    }

    for (let i = 0, p = 11; cpf.length - 1 > i; i += 1, p -= 1) {
      v2 += Number(cpf[i]) * p;
    }

    v2 = (v2 * 10) % 11;

    if (v2 === 10) {
      v2 = 0;
    }

    if (v2 !== Number(cpf[10])) {
      return false;
    }
    return true;
  }
  if (val.length === 18) {
    let cnpjStr = val.trim();

    cnpjStr = cnpjStr.replace(/\./g, '');
    cnpjStr = cnpjStr.replace('-', '');
    cnpjStr = cnpjStr.replace('/', '');
    const cnpj = cnpjStr.split('');

    let v1 = 0;
    let v2 = 0;
    let aux = false;

    for (let i = 1; cnpj.length > i; i += 1) {
      if (Number(cnpj[i - 1]) !== Number(cnpj[i])) {
        aux = true;
      }
    }

    if (aux === false) {
      return false;
    }

    for (
      let i = 0, p1 = 5, p2 = 13;
      cnpj.length - 2 > i;
      i += 1, p1 -= 1, p2 -= 1
    ) {
      if (p1 >= 2) {
        v1 += Number(cnpj[i]) * p1;
      } else {
        v1 += Number(cnpj[i]) * p2;
      }
    }

    v1 %= 11;

    if (v1 < 2) {
      v1 = 0;
    } else {
      v1 = 11 - v1;
    }

    if (v1 !== Number(cnpj[12])) {
      return false;
    }

    for (
      let i = 0, p1 = 6, p2 = 14;
      cnpj.length - 1 > i;
      i += 1, p1 -= 1, p2 -= 1
    ) {
      if (p1 >= 2) {
        v2 += Number(cnpj[i]) * p1;
      } else {
        v2 += Number(cnpj[i]) * p2;
      }
    }

    v2 %= 11;

    if (v2 < 2) {
      v2 = 0;
    } else {
      v2 = 11 - v2;
    }

    if (v2 !== Number(cnpj[13])) {
      return false;
    }
    return true;
  }
  return false;
};
