import { formatCurrencyBRL } from 'utils/formatCurrencyBRL';
import { capitalizeFirst } from 'utils/formatString';
import { useComplements } from 'hooks/useComplements';

import CounterComplements from 'components/CounterComplements';

import Checkbox from '@material-ui/core/Checkbox';
import RadioButtonCheckedRoundedIcon from '@material-ui/icons/RadioButtonCheckedRounded';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';

import { ComplementsProps } from 'models';

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useInformationsClient } from 'hooks/useInformationsClient';
import { Container, Description } from './styles';

interface ComplementsComponentProps {
  type: 'add' | 'rem';
  listComplements: ComplementsProps[];
  counterProduct?: number;
}

/*
Checkbox
https://mui.com/pt/components/checkboxes/
https://v3.mui.com/demos/selection-controls/
https://stackoverflow.com/questions/68862645/unable-to-only-select-a-single-material-ui-checkbox
https://stackblitz.com/edit/list-and-check-box-material-ui-example
https://omkz.net/en-mu-checkbox-rhf/
*/

const Complements: React.FC<ComplementsComponentProps> = ({
  type,
  listComplements,
  counterProduct,
}) => {
  const { selectCheckboxs } = useComplements();
  const array_checkbox = Array(Number(listComplements.length)).fill(false);
  const [checked, setChecked] = useState(array_checkbox);
  const [disabled, setDisabled] = useState([]);
  const seqOrdemWeb = listComplements.reduce(
    (accum: number, curr: ComplementsProps) =>
      accum + Number(curr.SQ_ORDEM_WEB),
    0,
  );
  const { companyData } = useInformationsClient();
  const sortByProperty = property => {
    return (a, b) => {
      if (a[property] > b[property]) {
        return 1;
      }
      if (a[property] < b[property]) {
        return -1;
      }
      return 0;
    };
  };
  let newListComplements = [];
  if (seqOrdemWeb > 0) {
    newListComplements = listComplements.sort(sortByProperty('SQ_ORDEM_WEB'));
  } else {
    newListComplements = listComplements.sort(sortByProperty('DS_ITEM'));
  }

  useEffect(() => {
    setChecked(Array(Number(listComplements.length)).fill(false));
    setDisabled(Array(Number(listComplements.length)).fill(false));
  }, [listComplements.length]);

  const handleChange = (event, index: number, item) => {
    const new_array = [...checked];
    const checkboxLimit = Number(listComplements[0].NR_GRUPO_QUANTIDADE_MAX);

    const div = document.getElementById(
      `${newListComplements[0].SQ_GRUPO_ESCOLHA + 1}`,
    );

    if (checkboxLimit > 0) {
      if (new_array.filter(val => val === true).length <= checkboxLimit) {
        if (new_array.filter(val => val === true).length === checkboxLimit) {
          if (!event.target.checked) {
            selectCheckboxs(item, type);
          }
        } else {
          selectCheckboxs(item, type);
          try {
            if (
              new_array.filter(val => val === true).length + 1 ===
                checkboxLimit &&
              event.target.checked
            ) {
              div.scrollIntoView({ behavior: 'smooth' });
            }
          } catch {
            // Ignore scroll error
          }
        }
      }
      if (event.target.checked) {
        if (new_array.filter(val => val === true).length < checkboxLimit) {
          new_array[index] = event.target.checked;
          if (
            checkboxLimit > 0 &&
            new_array.filter(val => val === true).length === checkboxLimit &&
            event.target.checked
          ) {
            const newDisableArray = [];
            new_array.forEach(e => {
              newDisableArray.push(!e);
            });
            setDisabled(newDisableArray);
          }
        }
      } else {
        new_array[index] = event.target.checked;
        setDisabled(Array(Number(listComplements.length)).fill(false));
      }
    } else {
      new_array[index] = event.target.checked;
      selectCheckboxs(item, type);
    }
    setChecked(new_array);
  };

  return (
    <>
      {newListComplements.length > 0 && (
        <Container>
          <div id="title-complement">
            <div id={newListComplements[0].SQ_GRUPO_ESCOLHA}>
              {companyData.setMenu === 'S' &&
              companyData.setMenuTable === companyData.table ? (
                <>
                  <div>
                    {newListComplements[0].NR_GRUPO_QUANTIDADE > 0 &&
                    newListComplements[0].NR_GRUPO_QUANTIDADE === 1 &&
                    newListComplements[0].ID_DM202 === 0 ? (
                      <p>
                        {type === 'add'
                          ? `Adicional de ${newListComplements[0].NR_GRUPO_QUANTIDADE} item:`
                          : `Remover ${newListComplements[0].NR_GRUPO_QUANTIDADE} item:`}
                      </p>
                    ) : (
                      <></>
                    )}
                    {newListComplements[0].NR_GRUPO_QUANTIDADE > 1 &&
                    newListComplements[0].ID_DM202 === 0 ? (
                      <p>
                        {type === 'add'
                          ? `Adicional de até ${newListComplements[0].NR_GRUPO_QUANTIDADE} itens:`
                          : `Remova até ${newListComplements[0].NR_GRUPO_QUANTIDADE} itens:`}
                      </p>
                    ) : (
                      <></>
                    )}
                    {newListComplements[0].NR_GRUPO_QUANTIDADE === 0 &&
                    newListComplements[0].ID_DM202 === 0 ? (
                      <p>{type === 'add' ? 'Adicionar:' : 'Remover:'}</p>
                    ) : (
                      <></>
                    )}
                    {newListComplements[0].ID_DM202 > 0 &&
                    newListComplements[0].NR_GRUPO_QUANTIDADE === 0 ? (
                      <p>
                        {capitalizeFirst(
                          newListComplements[0].DS_GRUPO_ESCOLHA,
                        )}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div>
                    {newListComplements[0].NR_GRUPO_QUANTIDADE === 1 &&
                    newListComplements[0].ID_DM202 === 0 ? (
                      <p>
                        {type === 'add' ? `Adicione 1 item:` : `Remova 1 item:`}
                      </p>
                    ) : (
                      <></>
                    )}
                    {newListComplements[0].NR_GRUPO_QUANTIDADE > 1 &&
                    newListComplements[0].ID_DM202 === 0 ? (
                      <p>
                        {type === 'add'
                          ? `Adicione até ${newListComplements[0].NR_GRUPO_QUANTIDADE} itens:`
                          : `Remova até ${newListComplements[0].NR_GRUPO_QUANTIDADE} itens:`}
                      </p>
                    ) : (
                      <></>
                    )}
                    {newListComplements[0].NR_GRUPO_QUANTIDADE === 0 &&
                    newListComplements[0].ID_DM202 === 0 ? (
                      <p>
                        {type === 'add'
                          ? 'Adicione conforme preferência:'
                          : 'Remova conforme preferência:'}
                      </p>
                    ) : (
                      <></>
                    )}
                    {newListComplements[0].ID_DM202 > 0 &&
                    newListComplements[0].NR_GRUPO_QUANTIDADE === 0 ? (
                      <p>
                        {capitalizeFirst(
                          newListComplements[0].DS_GRUPO_ESCOLHA,
                        )}
                        {newListComplements[0].NR_GRUPO_QUANTIDADE_MAX > 0 &&
                          ` - Escolha no máximo ${newListComplements[0].NR_GRUPO_QUANTIDADE_MAX}`}
                      </p>
                    ) : (
                      <></>
                    )}
                    {newListComplements[0].ID_DM202 > 0 &&
                    newListComplements[0].NR_GRUPO_QUANTIDADE > 0 ? (
                      <p>
                        Escolha {newListComplements[0].NR_GRUPO_QUANTIDADE}{' '}
                        {capitalizeFirst(
                          newListComplements[0].DS_GRUPO_ESCOLHA,
                        )}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          {newListComplements.map((item: ComplementsProps, index) => {
            return (
              <ul key={item.DS_ITEM}>
                <li>
                  <Description>
                    <label htmlFor={item.DS_ITEM}>{item.DS_ITEM}</label>
                    {item.VL_VENDA !== 0 ? (
                      <p className="complement-value">
                        + {formatCurrencyBRL(item.VL_VENDA)}
                      </p>
                    ) : (
                      <></>
                    )}
                  </Description>
                  {item.IN_SOLICITA_QUANTIDADE === 'S' ? (
                    <>
                      {companyData.setMenu === 'S' &&
                      companyData.setMenuTable === companyData.table ? (
                        <></>
                      ) : (
                        <>
                          <CounterComplements
                            complement={item}
                            counter={counterProduct || 1}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {companyData.setMenu === 'S' &&
                      companyData.setMenuTable === companyData.table ? (
                        <></>
                      ) : (
                        <>
                          <Checkbox
                            id={item.DS_ITEM}
                            icon={<RadioButtonUncheckedRoundedIcon />}
                            checkedIcon={<RadioButtonCheckedRoundedIcon />}
                            disabled={disabled[index]}
                            color="primary"
                            style={{
                              marginRight: '2rem',
                            }}
                            inputProps={{
                              'aria-label': `checkbox-${item.DS_ITEM}`,
                            }}
                            onChange={event => {
                              handleChange(event, index, item);
                            }}
                            classes={{ root: 'increase-size-check' }}
                          />
                        </>
                      )}
                    </>
                  )}
                </li>
              </ul>
            );
          })}
        </Container>
      )}
    </>
  );
};

export default Complements;
