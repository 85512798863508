export const maskCPF = (cpf: string): string => {
  const firstReplace = cpf.replace(/\D/g, '');
  const secondReplace = firstReplace.replace(/(\d{3})(\d)/, '$1.$2');
  const thirdReplace = secondReplace.replace(/(\d{3})(\d)/, '$1.$2');
  const maskFinal = thirdReplace.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  return maskFinal;
};
export const maskCNPJ = (cnpj: string): string => {
  const x = cnpj
    .replace(/\D/g, '')
    .match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
  return !x[2]
    ? x[1]
    : `${x[1]}.${x[2]}.${x[3]}/${x[4]}${x[5] ? `-${x[5]}` : ''}`;
};
