/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createContext, ReactNode, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface RoutesProviderProps {
  children: ReactNode;
}

interface RoutesContextData {
  currentRoute: string;
  updateRoute: (route: string) => void;
}

const RoutesContext = createContext<RoutesContextData>({} as RoutesContextData);

export const RoutesProvider = ({ children }: RoutesProviderProps) => {
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState(location.pathname);

  const updateRoute = (route: string) => {
    setCurrentRoute(route);
  };

  return (
    <RoutesContext.Provider value={{ currentRoute, updateRoute }}>
      {children}
    </RoutesContext.Provider>
  );
};

export const useRoutes = () => {
  const context = useContext(RoutesContext);

  return context;
};
