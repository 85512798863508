// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';

const apiAutoatendimento = axios.create({
  baseURL: 'https://sgbr.datamais.com.br/api/',
  // baseURL: 'http://192.168.0.190:8004/api/',
  // baseURL: 'https://deliveryapi.datamais.inf.br/marcelo/api-autoatendimento/',
  // baseURL: 'http://192.168.0.190:8000/marcelo/api-autoatendimento/',
  headers: {
    'content-type': 'application/json',
  },
  timeout: 30000,
});

export default apiAutoatendimento;
