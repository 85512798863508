import styled, { css } from 'styled-components';

interface isVisibleSearch {
  showSearch: boolean;
}

export const Container = styled.div<isVisibleSearch>`
  ${props =>
    // eslint-disable-next-line prettier/prettier
    props.showSearch === true
      ? css`
          margin-top: 1rem;
        `
      : css`
          margin-top: 0rem;
        `}

  .title {
    margin: 0.95rem auto 0.65rem 0.65rem;
    font-weight: 300;
    font-size: 2rem;

    text-align: center;

    background: #ff8c40;

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .grid-items {
    display: grid;
    grid-gap: 1rem;
    margin: 0 1rem;
  }

  @media screen and (min-width: 1200px) {
    .grid-items {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (min-width: 720px) and (max-width: 1200px) {
    .grid-items {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (min-width: 0) and (max-width: 719px) {
    .grid-items {
      grid-template-columns: 1fr;
    }
  }
`;
