import { PixKey } from '../enums/pix-key';

export function formatPixKey(pixKey: string, pixType: string): string {
  // CPF: 123.456.789-00
  let formattedPixKey = pixKey;
  if (pixType === PixKey.CPF) {
    formattedPixKey = pixKey.replace(
      /^(\d{3})(\d{3})(\d{3})(\d{2})/,
      '$1.$2.$3-$4',
    );
  }

  // CNPJ: 12.345.678/0001-12
  if (pixType === PixKey.CNPJ) {
    formattedPixKey = pixKey.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5',
    );
  }
  // Telefone: (12) 34567-8901
  if (pixType === PixKey.PHONE) {
    formattedPixKey = pixKey.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  }

  // Email:
  if (pixType === PixKey.EMAIL) {
    formattedPixKey = pixKey.toLocaleLowerCase();
  }

  return formattedPixKey;
}
