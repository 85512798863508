/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createContext, ReactNode, useContext, useState } from 'react';

interface UserTelephoneProviderProps {
  children: ReactNode;
}

interface UserTelephoneContextData {
  formatedNumber: number;
  unformatedNumber: string;
  accepted: boolean;
  setAccepted: React.Dispatch<React.SetStateAction<boolean>>;
  getTelephoneNumber: (number: any) => void;
}

const UserTelephoneContext = createContext<UserTelephoneContextData>(
  {} as UserTelephoneContextData,
);

export const UserTelephoneProvider = ({
  children,
}: UserTelephoneProviderProps) => {
  const [accepted, setAccepted] = useState(false);
  const [unformatedNumber, setUnformatedNumber] = useState('');
  const [formatedNumber, setFormatedNumber] = useState(0);

  const getTelephoneNumber = (number: number) => {
    setFormatedNumber(number);
    const unformatedNumberString = String(number)
      .replace(/\s+/g, '')
      .replace('-', '')
      .replace('(', '')
      .replace(')', '');
    setUnformatedNumber(unformatedNumberString);
    setAccepted(true);
  };

  return (
    <UserTelephoneContext.Provider
      value={{
        formatedNumber,
        unformatedNumber,
        accepted,
        setAccepted,
        getTelephoneNumber,
      }}
    >
      {children}
    </UserTelephoneContext.Provider>
  );
};

export const useUserTelephone = () => {
  const context = useContext(UserTelephoneContext);

  return context;
};
