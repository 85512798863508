import React, { useState } from 'react';

import { checkPromotion } from 'utils/checkPromotion';
import { formatCurrencyBRL } from 'utils/formatCurrencyBRL';
import { ProductsProps } from 'models';
import { Container } from './styles';

interface InformationsProductProps {
  height: number;
  product: ProductsProps;
}

const CardInformationsProduct: React.FC<InformationsProductProps> = ({
  height,
  product,
}) => {
  const [sale_promotion, sale_amount, promotion_value] =
    checkPromotion(product);

  return (
    <Container height={height}>
      <p className="title-product">{product.DS_ITEM}</p>
      {product.DS_DESCRICAO_PRODUTO !== undefined &&
      product.DS_DESCRICAO_PRODUTO.length > 30 ? (
        <>
          <p className="description-product">
            {product.DS_DESCRICAO_PRODUTO.substring(0, 30)}... Ler mais
          </p>
        </>
      ) : (
        <p className="description-product">{product.DS_DESCRICAO_PRODUTO}</p>
      )}
      {sale_promotion ? (
        <>
          {!sale_amount ? (
            <p className="value-product">Indisponível!</p>
          ) : (
            <p className="value-product">
              <s className="old-product-value">
                {formatCurrencyBRL(product.VL_VENDA)}
              </s>
              &nbsp;&nbsp;
              <span className="promotion-value">
                {formatCurrencyBRL(promotion_value)}
              </span>
            </p>
          )}
        </>
      ) : (
        <>
          <p className="value-product">{formatCurrencyBRL(product.VL_VENDA)}</p>
        </>
      )}
      <></>
    </Container>
  );
};

export default CardInformationsProduct;
