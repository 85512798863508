import styled from 'styled-components';

export const Container = styled.div`
  @media screen and (max-width: 1060px) {
    width: 95%;
    min-height: 2rem;
    max-height: 6rem;
  }

  @media screen and (min-width: 1060px) {
    width: 70%;
  }

  padding: 1rem;
  /* margin: 10 auto; */
  margin-top: 1rem;

  background-image: linear-gradient(
    to right,
    #f6f0ed,
    #f7f1ed,
    #f7f1ed,
    #f8f2ed,
    #f8f3ed
  );

  color: #000;
  border-radius: 8px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  #informationsAddress {
    width: 100%;

    flex-direction: column;
    padding: 0 1rem;

    gap: 1rem;

    button {
      border: none;
      background: transparent;
      align-items: left;
      background-color: transparent;
      outline: none !important;
      box-shadow: none !important;
      text-align: left;
    }
    button: focus {
      background: none;
    }

    #title-address {
      font-size: 14px;
      font-weight: 600;
    }

    button span {
      font-weight: 400;
      font-size: 10px;
    }
  }

  #actionsArea {
    height: 100%;
    max-height: 100%;
    min-width: 3rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    button {
      width: 1.25rem;
      height: 1.25rem;

      border: none;
      background: transparent;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
