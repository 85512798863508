import styled from 'styled-components';

interface Props {
  height: number;
}

export const Container = styled.div<Props>`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: ${props => `${props.height}%`};

  .title-product {
    font-size: 1rem;
  }

  .description-product {
    font-size: 0.9rem;
  }

  .value-product {
    font-size: 1rem;
  }

  .promotion-value {
    font-size: 1.1rem;
  }

  .old-product-value {
    font-size: 1rem;
    color: grey;
  }
`;
