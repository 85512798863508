import React from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-toastify/dist/ReactToastify.css';

import Footer from 'components/Footer';
import Header from 'components/Header';
import Routes from 'routes';

import { ComplementsProvider } from 'hooks/useComplements';
import { TasteComplementsProvider } from 'hooks/useTasteComplements';
import { TastesProvider } from 'hooks/useTastes';
import { CommentProvider } from 'hooks/useComments';
import { CartProvider } from 'hooks/useCart';
import { UserTelephoneProvider } from 'hooks/useUserTelephone';
import { RoutesProvider } from 'hooks/useRoutes';
import { InformationsClientProvider } from 'hooks/useInformationsClient';
import { DateLoginProvider } from 'hooks/useDateLogin';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Bounce, toast } from 'react-toastify';
import GlobalStyle from './styles/global';

export const theme = createTheme({
  palette: {
    primary: { main: '#F47321' },
    secondary: { main: '#FDF7F7' },
    error: { main: '#f44336' },
    info: { main: '#2196f3' },
    success: { main: '#2A7925' },

    contrastThreshold: 3,

    tonalOffset: 0.2,
  },
});

toast.configure({
  position: toast.POSITION.TOP_RIGHT,
  transition: Bounce,
  draggable: false,
  role: 'Warnings',
  autoClose: 3000,
});

const App: React.FC = () => {
  // LIMPAR STORAGE AO FECHAR ABA OU DAR F5
  // window.onunload = () => {
  //   window.localStorage.clear();
  // };
  // <BrowserRouter basename="/autoatendimento"> use for production build
  // <BrowserRouter"> use for development
  return (
    <BrowserRouter basename="/">
      <ThemeProvider theme={theme}>
        <InformationsClientProvider>
          <UserTelephoneProvider>
            <DateLoginProvider>
              <ComplementsProvider>
                <TastesProvider>
                  <TasteComplementsProvider>
                    <CartProvider>
                      <CommentProvider>
                        <RoutesProvider>
                          <Routes />
                          <Footer />
                          <GlobalStyle />
                        </RoutesProvider>
                      </CommentProvider>
                    </CartProvider>
                  </TasteComplementsProvider>
                </TastesProvider>
              </ComplementsProvider>
            </DateLoginProvider>
          </UserTelephoneProvider>
        </InformationsClientProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
