import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1.25rem;

  box-shadow: 0px 0px 5px 2px rgba(200, 200, 200, 0.5);
  border: 1px solid var(--line-color);
  border-radius: 8px;

  height: 12rem;

  background-image: linear-gradient(
    to right,
    #f6f0ed,
    #f7f1ed,
    #f7f1ed,
    #f8f2ed,
    #f8f3ed
  );

  img {
    width: 10rem;
    height: 10rem;
    border-radius: 8px;
  }

  @media screen and (max-width: 640px) {
    img {
      width: 8rem;
      height: 8rem;
      border-radius: 8px;
    }
  }
`;
