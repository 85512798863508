/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Lottie from 'react-lottie';
import { Base64 } from 'js-base64';
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify';
import { useRoutes } from 'hooks/useRoutes';
import { useInformationsClient } from 'hooks/useInformationsClient';
import { setStorage, getStorage } from 'services/storage';
import apiAutoatendimento from 'services/apiAutoatendimento';

import AnimationLoading from 'assets/animations/loading.json';
import axios from 'axios';
import { Container } from './styles';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: AnimationLoading,
};

interface HashProps {
  props: string;
}

const Hash: React.FC<HashProps> = ({ props }) => {
  const history = useHistory();
  const { updateRoute } = useRoutes();
  const { setCompanyData } = useInformationsClient();

  localStorage.setItem('lastCategory', '0');

  useEffect(() => {
    const json_hash = {
      get_hash: {
        hash: props,
      },
    };

    apiAutoatendimento
      .post('json_hash', json_hash)
      .then(responseHash => {
        const companyCode =
          responseHash.data.response.data.hash_return.establishment;
        const command =
          responseHash.data.response.data.hash_return.guest_check_pad;
        const table = Number(responseHash.data.response.data.hash_return.table);
        const json_company = {
          Id_dm000: companyCode,
        };

        if (
          getStorage('companyCode') !== null &&
          json_company.Id_dm000 !==
            getStorage('companyCode').replaceAll('"', '')
        ) {
          localStorage.clear();
        }

        apiAutoatendimento
          .post('get_location_aa', json_company)
          .then(response => {
            const latClient = response.data.response.data.get_location.latitude;
            const lonClient =
              response.data.response.data.get_location.longitude;
            const radiusClient = response.data.response.data.get_location.raio;
            const descClient =
              response.data.response.data.get_location.desc_local;
            const attendantCode =
              response.data.response.data.get_location.cd_atendente;
            const printerCode =
              response.data.response.data.get_location.cd_impressora;
            const urlNgrok = response.data.response.data.get_location.token;
            const setUseCustomer =
              response.data.response.data.get_location.in_informa_cliente;
            const setUsePhone =
              response.data.response.data.get_location.in_informa_fone;
            const setUseLocation =
              response.data.response.data.get_location.in_informa_localizacao;
            const setUseBanner =
              response.data.response.data.get_location.in_mostra_banner;
            const setUseGroups =
              response.data.response.data.get_location.in_filtro_grupo;
            const partnerPassword =
              response.data.response.data.get_location.in_senha_cliente;
            const setNotification =
              response.data.response.data.get_location.in_notificacao;
            const setMenu =
              response.data.response.data.get_location.in_cardapio;
            const setMenuTable =
              response.data.response.data.get_location.in_mesa_cardapio;
            const setLocalConsumption = response.data.response.data.get_location
              .in_exibe_consumo_local
              ? response.data.response.data.get_location.in_exibe_consumo_local
              : 'S';
            const setAutoConference =
              response.data.response.data.get_location
                .in_conferencia_automatica;
            const setAutoConferenceTable =
              response.data.response.data.get_location
                .in_mesa_conferencia_automatica;
            const apiNgrok = axios.create({
              baseURL: `${urlNgrok}/datasnap/rest/Tdm_ServerMethods`,
              headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Bypass-Tunnel-Reminder': '*',
              },
            });
            const setBlockConference =
              response.data.response.data.get_location.in_bloqueia_conferencia;
            const setDelivery =
              response.data.response.data.get_location.in_delivery;
            const setDeliveryTable = Number(
              response.data.response.data.get_location.in_mesa_delivery,
            );
            const setGetLocalDelivery =
              response.data.response.data.get_location.in_retirada_delivery;
            const setDeliveryAddress =
              response.data.response.data.get_location.in_endereco_delivery;
            const setWhatsAppNumber =
              response.data.response.data.get_location.in_nr_whats_delivery;
            const setWhatsAppText =
              response.data.response.data.get_location.in_txt_whats_delivery;
            // eslint-disable-next-line prefer-const
            let guestCheckPad = 0;
            apiNgrok
              .get(`fprg_GetEmpresaJSON`)
              .then(responseNgrok => {
                const commandValue =
                  responseNgrok.data.result[0][0].IN_UTILIZA_COMANDA;
                if (commandValue === 'S') {
                  guestCheckPad = 1;
                }
                const useTip =
                  responseNgrok.data.result[0][0].IN_TAXA_SERVICO === 'S';
                const useMandatoryTipTax =
                  responseNgrok.data.result[0][0]
                    .IN_TAXA_SERVICO_OBRIGATORIA === 'S';
                const cityPix = responseNgrok.data.result[0][0].NM_CIDADEPIX
                  ? responseNgrok.data.result[0][0].NM_CIDADEPIX
                  : '';
                const nmPix = responseNgrok.data.result[0][0].NM_BENEFICIARIOPIX
                  ? responseNgrok.data.result[0][0].NM_BENEFICIARIOPIX
                  : '';
                const tpPix = responseNgrok.data.result[0][0].IN_TIPO_CHAVEPIX
                  ? responseNgrok.data.result[0][0].IN_TIPO_CHAVEPIX
                  : '';
                const keyPix = responseNgrok.data.result[0][0].DS_CHAVEPIX
                  ? responseNgrok.data.result[0][0].DS_CHAVEPIX
                  : '';

                apiNgrok
                  .get(`fprg_GetParametrosAutomacaoJSON/${companyCode}/0/0`)
                  .then(responsePars => {
                    const setPriceItemTaste =
                      responsePars.data.result[0][0].IN_PRECO_PIZZA ===
                      undefined
                        ? 'N'
                        : responsePars.data.result[0][0].IN_PRECO_PIZZA;
                    setStorage('cart', []);
                    setStorage('complements', []);
                    setStorage('tastes', []);
                    setStorage('comment', {});
                    setStorage('tasteComplements', []);

                    setCompanyData({
                      companyCode: Number(companyCode),
                      desClient: descClient,
                      command: 0,
                      guestCheckPad,
                      tip: useTip,
                      mandatoryTipTax: useMandatoryTipTax,
                      cellPhone: '',
                      table,
                      lat: latClient,
                      lon: lonClient,
                      radius: Number(radiusClient),
                      ngrok: urlNgrok,
                      attendantCode: Number(attendantCode),
                      printerCode: Number(printerCode),
                      setCustomer: setUseCustomer,
                      setPhone: setUsePhone,
                      setLocation: setUseLocation,
                      setBanner: setUseBanner,
                      setGroups: setUseGroups,
                      setBlockConference,
                      setPartnerPassword: partnerPassword,
                      setDelivery,
                      setDeliveryTable,
                      setNotification,
                      setMenu,
                      setMenuTable,
                      setAutoConference,
                      setAutoConferenceTable: Number(setAutoConferenceTable),
                      setPriceItemTaste,
                      setGetLocalDelivery,
                      setDeliveryAddress,
                      setWhatsAppNumber,
                      setWhatsAppText,
                      // @ts-ignore
                      cityPix,
                      nmPix,
                      tpPix,
                      keyPix,
                    });
                    setStorage('setLocalConsumption', setLocalConsumption);
                    if (guestCheckPad === 1) {
                      setStorage('companyCode', companyCode);
                      setStorage('descClient', descClient);
                      setStorage('command', command);
                      setStorage('guestCheckPad', guestCheckPad);
                      setStorage('useTip', useTip);
                      setStorage('useMandatoryTipTax', useMandatoryTipTax);
                      setStorage('cellPhone', '');
                      setStorage('table', table);
                      setStorage('lat', latClient);
                      setStorage('lon', lonClient);
                      setStorage('radius', radiusClient);
                      setStorage('ngrok', urlNgrok);
                      setStorage('attendantCode', attendantCode);
                      setStorage('printerCode', printerCode);
                      setStorage('dtSave', new Date());
                      setStorage('setCustomer', setUseCustomer);
                      setStorage('setPhone', setUsePhone);
                      setStorage('setLocation', setUseLocation);
                      setStorage('setBanner', setUseBanner);
                      setStorage('setGroups', setUseGroups);
                      setStorage('setBlockConference', setBlockConference);
                      setStorage('setPartnerPassword', partnerPassword);
                      setStorage('setUserCode', 0);
                      setStorage('setDelivery', setDelivery);
                      setStorage('setDeliveryTable', setDeliveryTable);
                      setStorage('setNotification', setNotification);
                      setStorage('setMenu', setMenu);
                      setStorage('setMenuTable', setMenuTable);
                      setStorage('setAutoConference', setAutoConference);
                      setStorage(
                        'setAutoConferenceTable',
                        setAutoConferenceTable,
                      );
                      setStorage('setPriceItemTaste', setPriceItemTaste);
                      setStorage('setGetLocalDelivery', setGetLocalDelivery);
                      setStorage('setDeliveryAddress', setDeliveryAddress);
                      setStorage('setWhatsAppNumber', setWhatsAppNumber);
                      setStorage('setWhatsAppText', setWhatsAppText);
                      updateRoute('/');
                      history.push('/');
                    } else {
                      setStorage('companyCode', companyCode);
                      setStorage('descClient', descClient);
                      setStorage('command', table);
                      setStorage('guestCheckPad', guestCheckPad);
                      setStorage('useTip', useTip);
                      setStorage('useMandatoryTipTax', useMandatoryTipTax);
                      setStorage('cellPhone', '');
                      setStorage('table', table);
                      setStorage('lat', latClient);
                      setStorage('lon', lonClient);
                      setStorage('radius', radiusClient);
                      setStorage('ngrok', urlNgrok);
                      setStorage('attendantCode', attendantCode);
                      setStorage('printerCode', printerCode);
                      setStorage('dtSave', new Date());
                      setStorage('setCustomer', setUseCustomer);
                      setStorage('setPhone', setUsePhone);
                      setStorage('setLocation', setUseLocation);
                      setStorage('setBanner', setUseBanner);
                      setStorage('setGroups', setUseGroups);
                      setStorage('setBlockConference', setBlockConference);
                      setStorage('setPartnerPassword', partnerPassword);
                      setStorage('setUserCode', 0);
                      setStorage('setDelivery', setDelivery);
                      setStorage('setDeliveryTable', setDeliveryTable);
                      setStorage('setNotification', setNotification);
                      setStorage('setMenu', setMenu);
                      setStorage('setMenuTable', setMenuTable);
                      setStorage('setAutoConference', setAutoConference);
                      setStorage(
                        'setAutoConferenceTable',
                        setAutoConferenceTable,
                      );
                      setStorage('setPriceItemTaste', setPriceItemTaste);
                      setStorage('setGetLocalDelivery', setGetLocalDelivery);
                      setStorage('setDeliveryAddress', setDeliveryAddress);
                      setStorage('setWhatsAppNumber', setWhatsAppNumber);
                      setStorage('setWhatsAppText', setWhatsAppText);
                      setStorage('cityPix', cityPix);
                      setStorage('nmPix', nmPix);
                      setStorage('tpPix', tpPix);
                      setStorage('keyPix', keyPix);
                      updateRoute('/');
                      history.push('/');
                    }
                  })
                  .catch(error => {
                    toast.error(
                      'Erro ao acessar o sistema do estabelecimento.',
                      {
                        autoClose: 10000,
                      },
                    );
                    console.log(error);
                  });
              })
              .catch(error => {
                toast.error('Erro ao acessar o sistema do estabelecimento.', {
                  autoClose: 10000,
                });
                console.log(error);
              });
          })
          .catch(error => {
            throw new Error(`Sem empresa para ser carregada: ${error}`);
          });
      })
      .catch(error => {
        toast.error('Erro ao ler QR-Code.', {
          autoClose: 10000,
        });
        console.log(error);
      });
    // eslint-disable-next-line
  }, [props, history, updateRoute, setCompanyData]);

  return (
    <Container>
      <Lottie options={defaultOptions} style={{ width: '100%', height: 400 }} />
      <h1>Carregando...</h1>
    </Container>
  );
};

export default Hash;
