import styled, { keyframes } from 'styled-components';

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  height: calc(100vh - 12rem);
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;

  .qrPix {
    width: 100%;
    margin-top: 30px;
      h4 {
        padding-top: 10px;
        text-align: center !important;
        font-size: 20px;
      }

      .btnInfos {
        display: flex;
        width: 100%;
        margin-bottom: 20px;

        button {
          width: 50%;
          background: none;
          border: none;
          ustify-content: left;
          animation: wiggle 5s infinite;

          svg {
            margin-right: 8px;
            color: #2EBDAF;
          }
        }

        p {
          width: 50% !important;
          ustify-content: right;
        }

        p, button {
          font-size: 18px !important;
          height: 25px;
          display: flex;
          align-items: center;
          margin: 0px !important;
        }

        .infoPix {
          margin-top: 20px;
          width: 100%;
        }
      }
      .copyPix {
        margin-top: 20px;
        justify-content: space-between;
        width: 100% !important;
        display: flex;

        input {
          width: 90% !important;
          border: 0px;
          border-bottom: 1px solid;
          font-size: 18px;
        }
        svg {
          font-size: 20px;
        }

      }
      
      .pixText {
        text-align: center;
      }
      img {
        width: 100%;
      }
    }
  }

  @keyframes wiggle {
    2% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg);
    }
    4% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg);
    }
    6% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg);
    }
    8% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg);
    }
    10% {
      -webkit-transform: translateX(2px) rotate(1deg);
      transform: translateX(2px) rotate(1deg);
    }
    12% {
      -webkit-transform: translateX(-2px) rotate(-1deg);
      transform: translateX(-2px) rotate(-1deg);
    }
    14% {
      -webkit-transform: translateX(2px) rotate(1deg);
      transform: translateX(2px) rotate(1deg);
    }
    16% {
      -webkit-transform: translateX(-2px) rotate(-1deg);
      transform: translateX(-2px) rotate(-1deg);
    }
    18% {
      -webkit-transform: translateX(1px) rotate(0);
      transform: translateX(1px) rotate(0);
    }
    20% {
      -webkit-transform: translateX(-1px) rotate(0);
      transform: translateX(-1px) rotate(0);
    }
  }
  
`;

export const ContentCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: #fff;

  // PEQUENO
  @media screen and (max-width: 1060px) {
    width: 100%;
    height: 100%;
  }

  // GRANDE
  @media screen and (min-width: 1060px) {
    width: 50%;
    height: 95%;
    max-height: 95%;

    box-shadow: 0px 0px 5px 2px rgba(200, 200, 200, 0.5);
    border: 1px solid var(--line-color);
    border-radius: 8px;
  }
  p {
    align-items: center;
    font-size: 1rem;
  }

  header {
    min-height: 7.5rem;
    max-height: 7.5rem;

    border-bottom: 0.5px solid var(--line-color);
    border-radius: 8px;

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr 1fr 1fr;
    align-items: center;

    padding: 0.5rem;

    img {
      width: 3rem;
      height: 3rem;
    }

    span {
      font-weight: bold;
    }

    #container-logo {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .center-text {
      text-align: center;
    }

    p:not(.center-text) {
      margin-left: 0.5rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  main {
    min-height: calc(100% - 14rem);
    max-height: calc(100% - 14rem);

    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    table {
      width: 100%;

      tr {
        display: flex;
        justify-content: space-between;

        border-bottom: 1px solid var(--primary-color);

        th {
          min-width: 25%;
          max-width: 25%;
          text-align: center;

          margin: 0.5rem 0;

          color: var(--primary-color);
        }

        td {
          min-width: 25%;
          max-width: 25%;

          margin: 0.5rem 0;

          font-weight: 100;

          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          li {
            text-align: center;
            list-style: none;
          }
        }

        td.table-price {
          font-weight: 400;
          color: var(--price-color);
        }
      }
    }

    #alignment {
      width: 100%;

      padding: 2rem;

      div + p {
        margin-top: 1rem;
        font-weight: 400;
      }
    }
  }

  footer {
    width: 100%;

    min-height: 3rem;
    max-height: 3rem;

    display: grid;
    align-items: center;

    p {
      font-size: 1.2rem;
    }
    button {
      margin: 0 auto;
      width: 98%;
      height: 4rem;
      text-align: center;
    }
  }
`;

export const ContainerCardsFooter = styled.div`
  width: 100%;
`;

export const ContainerCardsSingle = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;

  font-size: 2rem;
`;

export const ContainerCards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  button {
    width: 40%;
    height: 3.5rem;
    text-align: center;
    margin: 5rem 2rem;
  }
`;

export const Card = styled.div`
  margin: 0rem 0.6rem 0rem 0.6rem;
  border: 0.5px solid var(--line-color);
  border-radius: 0.5rem;
  box-shadow: 0px 0px 5px 2px rgba(200, 200, 200, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  padding: 0.8rem;

  text-align: center;

  color: #fff;
  font-size: 1rem;
  background-color: #33cc95;

  &:first-child {
    background-color: var(--primary-color);
  }

  &:last-child {
    background-color: #33cc95;
  }
`;

export const CardDelivery = styled.div`
  margin: 0rem 1rem 0rem 1rem;
  border: 0.5px solid var(--line-color);
  border-radius: 0.5rem;
  box-shadow: 0px 0px 5px 2px rgba(200, 200, 200, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  padding: 0.8rem;

  text-align: center;

  color: #fff;
  font-size: 0.5rem;
  background-color: #f47321;

  &:first-child {
    background-color: var(--primary-color);
  }

  &:last-child {
    background-color: #f47321;
  }
`;
export const CardModal = styled.div`
  #modalContent {
    width: 100%;
    min-height: 3.75rem;
    max-height: 3.75rem;

    border-radius: 8px;

    display: grid;
    grid-template-columns: 45% 1fr;
    justify-items: center;
    align-items: center;

    .text-modal-command {
      font-size: 1.25rem;
      font-weight: 100;
      color: black;
    }

    .text-modal {
      font-size: 1.2rem;
    }

    .buttons-modal-command {
      width: 100%;

      display: flex;

      justify-content: space-between;
      align-items: end;

      margin-top: 2.25rem;

      button {
        width: 45%;
        height: 2.5rem;

        font-size: 1rem;
      }
    }
  }
`;

export const ContainerLoading = styled.div`
  height: calc(100vh - 7.5rem);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--primary-color);
    text-align: center;
  }
`;
