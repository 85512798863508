import React, { useCallback, useState } from 'react';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core/';
import { IoFastFoodOutline } from 'react-icons/io5';
import { FiHome, FiShoppingCart } from 'react-icons/fi';
import { HiOutlineCurrencyDollar, HiOutlineLogout } from 'react-icons/hi';
import SpecificApiLocalClient from 'services/api';
import { toast } from 'react-toastify';
import { useInformationsClient } from 'hooks/useInformationsClient';
import { FaConciergeBell } from 'react-icons/fa';
import { BsList } from 'react-icons/bs';
import { Link, useHistory } from 'react-router-dom';
import { useRoutes } from 'hooks/useRoutes';
import { useCart } from 'hooks/useCart';
import Modal from 'components/Modal';
import { useUserTelephone } from 'hooks/useUserTelephone';
import { getStorage, setStorage } from 'services/storage';
import { theme } from '../../App';
import { Container, ButtonCart, NotificationCart, CardModal } from './styles';

const Footer: React.FC = () => {
  const { currentRoute, updateRoute } = useRoutes();
  const { cart, setCart } = useCart();
  const { setAccepted } = useUserTelephone();

  const handleChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    newValue: string,
  ) => {
    updateRoute(newValue);
  };

  const [waiterModal, setwaiterModal] = useState(false);
  const [exitModal, setExitModal] = useState(false);
  const history = useHistory();
  const { companyData } = useInformationsClient();
  const api = SpecificApiLocalClient();

  const deleteAddressStorage = () => {
    let storageAddress = JSON.parse(getStorage('address_list'));

    storageAddress = storageAddress.filter(
      x => x.type === 'retirada' || x.type === 'consumir',
    );
    setStorage('address_list', storageAddress);
  };

  const handleCallWaiter = useCallback(() => {
    let query: {
      _parameters: { NR_CONTA: number; DS_LOCAL: string }[];
    };
    if (companyData.command !== 0) {
      query = {
        _parameters: [
          {
            NR_CONTA: companyData.command,
            DS_LOCAL: `Mesa ${companyData.table}`,
          },
        ],
      };
    } else if (companyData.cellPhone !== '') {
      query = {
        _parameters: [
          {
            NR_CONTA: Number(companyData.cellPhone),
            DS_LOCAL: `Mesa ${companyData.table}`,
          },
        ],
      };
    } else {
      query = {
        _parameters: [
          {
            NR_CONTA: companyData.table,
            DS_LOCAL: `Mesa ${companyData.table}`,
          },
        ],
      };
    }
    api
      .post(
        `/%22fprg_registraChamadoAtendenteJSON%22/${companyData.companyCode}`,
        query,
      )
      .then(() => {
        toast.success('Notificação enviada com sucesso!');
      })
      .catch(() => {
        toast.error('Erro ao realizar chamado!');
      });
  }, [api, companyData]);

  const waiterControll = true;

  return (
    <>
      {companyData.setMenu === 'S' && companyData.setMenuTable === 0 ? (
        <>
          {companyData.setNotification === 'S' ? (
            <>
              <CardModal>
                <div id="modalContent">
                  <Modal
                    visible={waiterModal}
                    setVisible={setwaiterModal}
                    maxHeight={30}
                    maxWidth={20}
                  >
                    <h2 className="text-modal-waiter">
                      Deseja chamar o atendente?
                    </h2>

                    <div className="buttons-modal-waiter">
                      <button
                        type="button"
                        className="button-outline"
                        onClick={() => {
                          setwaiterModal(false);
                          handleCallWaiter();
                          updateRoute('/');
                          history.push('/');
                        }}
                      >
                        Sim
                      </button>
                      <button
                        type="button"
                        className="button-filled"
                        onClick={() => {
                          setwaiterModal(false);
                          updateRoute('/');
                          history.push('/');
                        }}
                      >
                        Não
                      </button>
                    </div>
                  </Modal>
                  <Modal
                    visible={exitModal}
                    setVisible={setExitModal}
                    maxHeight={30}
                    maxWidth={20}
                  >
                    <h2 className="text-modal-waiter">
                      Deseja sair da plataforma?
                    </h2>

                    <div className="buttons-modal-waiter">
                      <button
                        type="button"
                        className="button-outline"
                        onClick={() => {
                          setExitModal(false);
                          deleteAddressStorage();
                          localStorage.removeItem('clientInfo');
                          setStorage('lastCategory', 0);
                          setStorage('cart', []);
                          setCart([]);
                          setStorage('deliveryPhone', '');
                          setAccepted(false);
                          updateRoute('/');
                          history.push('/');
                        }}
                      >
                        Sim
                      </button>
                      <button
                        type="button"
                        className="button-filled"
                        onClick={() => {
                          setExitModal(false);
                          updateRoute('/');
                          history.push('/');
                        }}
                      >
                        Não
                      </button>
                    </div>
                  </Modal>
                </div>
              </CardModal>
              <Container>
                <BottomNavigation
                  value={currentRoute}
                  onChange={handleChange}
                  style={{
                    justifyContent: 'space-around',
                    width: '100%',
                    background: 'transparent',
                  }}
                >
                  {companyData.setNotification === 'S' && (
                    <BottomNavigationAction
                      label="Atendimento"
                      onClick={() => {
                        setwaiterModal(true);
                      }}
                      icon={
                        <FaConciergeBell
                          style={{
                            color: waiterModal
                              ? theme.palette.primary.main
                              : 'rgba(0, 0, 0, 0.5)',
                          }}
                          size={waiterModal ? 24 : 18}
                        />
                      }
                      style={{
                        color: 'rgba(0, 0, 0, 0.5)',
                        display: waiterControll ? 'block' : 'none',
                      }}
                    />
                  )}
                </BottomNavigation>
              </Container>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <CardModal>
            <div id="modalContent">
              <Modal
                visible={waiterModal}
                setVisible={setwaiterModal}
                maxHeight={30}
                maxWidth={20}
              >
                <h2 className="text-modal-waiter">
                  Deseja chamar o atendente?
                </h2>

                <div className="buttons-modal-waiter">
                  <button
                    type="button"
                    className="button-outline"
                    onClick={() => {
                      setwaiterModal(false);
                      handleCallWaiter();
                      updateRoute('/');
                      history.push('/');
                    }}
                  >
                    Sim
                  </button>
                  <button
                    type="button"
                    className="button-filled"
                    onClick={() => {
                      setwaiterModal(false);
                      updateRoute('/');
                      history.push('/');
                    }}
                  >
                    Não
                  </button>
                </div>
              </Modal>
              <Modal
                visible={exitModal}
                setVisible={setExitModal}
                maxHeight={30}
                maxWidth={20}
              >
                <h2 className="text-modal-waiter">
                  Deseja sair da plataforma?
                </h2>

                <div className="buttons-modal-waiter">
                  <button
                    type="button"
                    className="button-outline"
                    onClick={() => {
                      setExitModal(false);
                      deleteAddressStorage();
                      localStorage.removeItem('clientInfo');
                      setStorage('lastCategory', 0);
                      setStorage('cart', []);
                      setCart([]);
                      setStorage('deliveryPhone', '');
                      setAccepted(false);
                      updateRoute('/');
                      history.push('/');
                    }}
                  >
                    Sim
                  </button>
                  <button
                    type="button"
                    className="button-filled"
                    onClick={() => {
                      setExitModal(false);
                      updateRoute('/');
                      history.push('/');
                    }}
                  >
                    Não
                  </button>
                </div>
              </Modal>
            </div>
          </CardModal>
          <Container>
            <BottomNavigation
              value={currentRoute}
              onChange={handleChange}
              style={{
                justifyContent: 'space-around',
                width: '100%',
                background: 'transparent',
              }}
            >
              <BottomNavigationAction
                component={Link}
                to="/"
                value="/"
                label="Início"
                icon={
                  <FiHome
                    style={{
                      color:
                        currentRoute === '/'
                          ? theme.palette.primary.main
                          : 'rgba(0, 0, 0, 0.5)',
                    }}
                    size={currentRoute === '/' ? 24 : 18}
                  />
                }
                style={{
                  color:
                    currentRoute === '/'
                      ? theme.palette.primary.main
                      : 'rgba(0, 0, 0, 0.5)',
                }}
              />

              <ButtonCart to="/cart" onClick={() => updateRoute('/cart')}>
                <button type="button">
                  {cart.length > 0 && (
                    <NotificationCart>{cart.length}</NotificationCart>
                  )}
                  <FiShoppingCart
                    style={{
                      color:
                        currentRoute === '/cart'
                          ? theme.palette.primary.main
                          : 'rgba(0, 0, 0, 0.5)',
                    }}
                    size={currentRoute === '/cart' ? 24 : 18}
                  />
                  {currentRoute === '/cart' && (
                    <p
                      style={{
                        color:
                          currentRoute === '/cart'
                            ? theme.palette.primary.main
                            : 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      Carrinho
                    </p>
                  )}
                </button>
              </ButtonCart>
              {companyData.setDeliveryTable !== companyData.table && (
                <BottomNavigationAction
                  component={Link}
                  to="/command"
                  value="/command"
                  label="Conferência"
                  icon={
                    <HiOutlineCurrencyDollar
                      style={{
                        color:
                          currentRoute === '/command'
                            ? theme.palette.primary.main
                            : 'rgba(0, 0, 0, 0.5)',
                      }}
                      size={currentRoute === '/command' ? 24 : 18}
                    />
                  }
                  style={{
                    color:
                      currentRoute === '/command'
                        ? theme.palette.primary.main
                        : 'rgba(0, 0, 0, 0.5)',
                  }}
                />
              )}
              {/* TODO: parametrizar para somente utilizar o sair quando não for
            tablet */}
              {/*
          <BottomNavigationAction
            component={Link}
            to="/changes"
            value="/changes"
            label="Opções"
            icon={
              <BsList
                style={{
                  color:
                    currentRoute === '/changes'
                      ? theme.palette.primary.main
                      : 'rgba(0, 0, 0, 0.5)',
                }}
                size={currentRoute === '/changes' ? 24 : 18}
              />
            }
            style={{
              color:
                currentRoute === '/changes'
                  ? theme.palette.primary.main
                  : 'rgba(0, 0, 0, 0.5)',
            }}
          /> */}
              {companyData.setDelivery === 'S' &&
                companyData.setDeliveryTable === companyData.table && (
                  <BottomNavigationAction
                    component={Link}
                    to="/profile/last-orders"
                    value="/profile/last-orders"
                    label="Pedidos"
                    icon={
                      <IoFastFoodOutline
                        style={{
                          color:
                            currentRoute === '/profile/last-orders'
                              ? theme.palette.primary.main
                              : 'rgba(0, 0, 0, 0.5)',
                        }}
                        size={currentRoute === '/profile/last-orders' ? 24 : 18}
                      />
                    }
                    style={{
                      color:
                        currentRoute === '/last-orders'
                          ? theme.palette.primary.main
                          : 'rgba(0, 0, 0, 0.5)',
                    }}
                  />
                )}
              {companyData.setDelivery === 'S' &&
                companyData.setDeliveryTable === companyData.table && (
                  <BottomNavigationAction
                    label="Sair"
                    onClick={() => {
                      setExitModal(true);
                    }}
                    icon={
                      <HiOutlineLogout
                        style={{
                          color: exitModal
                            ? theme.palette.primary.main
                            : 'rgba(0, 0, 0, 0.5)',
                        }}
                        size={exitModal ? 24 : 18}
                      />
                    }
                    style={{
                      color: exitModal
                        ? theme.palette.primary.main
                        : 'rgba(0, 0, 0, 0.5)',
                    }}
                  />
                )}

              {companyData.setNotification === 'S' &&
                companyData.setDeliveryTable !== companyData.table && (
                  <BottomNavigationAction
                    label="Atendimento"
                    onClick={() => {
                      setwaiterModal(true);
                    }}
                    icon={
                      <FaConciergeBell
                        style={{
                          color: waiterModal
                            ? theme.palette.primary.main
                            : 'rgba(0, 0, 0, 0.5)',
                        }}
                        size={waiterModal ? 24 : 18}
                      />
                    }
                    style={{
                      color: 'rgba(0, 0, 0, 0.5)',
                      display: waiterControll ? 'block' : 'none',
                    }}
                  />
                )}
            </BottomNavigation>
          </Container>
        </>
      )}
    </>
  );
};

export default Footer;
