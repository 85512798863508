/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  createContext,
  useState,
  ReactNode,
  useContext,
  useCallback,
} from 'react';
import { TastePost } from 'models';
import { setStorage } from 'services/storage';

interface TastesProviderProps {
  children: ReactNode;
}

interface TastesContextData {
  tastes: TastePost[];
  setTastes: React.Dispatch<React.SetStateAction<TastePost[]>>;
  selectedCheckbox: (checkbox: TastePost, taste: number) => void;
  clearTastes: () => void;
}

const TastesContext = createContext<TastesContextData>({} as TastesContextData);

export const TastesProvider = ({ children }: TastesProviderProps) => {
  const [tastes, setTastes] = useState<TastePost[]>([]);
  const selectedCheckbox = useCallback(
    (checkbox: TastePost, taste: number) => {
      checkbox.qt_proporcao = 1;
      checkbox.ds_proporcao = '';

      let selectedTaste;
      if (tastes.some(index => index.id_dm089 === checkbox.id_dm089)) {
        const filteredTastes = tastes.filter(
          index => index.id_dm089 !== checkbox.id_dm089,
        );
        selectedTaste = filteredTastes;
        const tmpSelectedTaste = selectedTaste.map(v => {
          return {
            VL_ITEM_SABOR: v.VL_ITEM_SABOR,
            cd_item: v.cd_item,
            cd_item_sabor: v.cd_item_sabor,
            ds_item: v.ds_item,
            ds_proporcao: v.ds_proporcao,
            id_dm004: v.id_dm004,
            id_dm004_sabor: v.id_dm004_sabor,
            id_dm089: v.id_dm089,
            is_checked: v.is_checked,
            qt_item_sabor: v.qt_item_sabor,
            qt_proporcao: 1 / selectedTaste.length,
          };
        });
        selectedTaste = tmpSelectedTaste;
        setTastes(filteredTastes);
      } else {
        selectedTaste = [...tastes, checkbox];
        const tmpSelectedTaste = selectedTaste.map(v => {
          return {
            VL_ITEM_SABOR: v.VL_ITEM_SABOR,
            cd_item: v.cd_item,
            cd_item_sabor: v.cd_item_sabor,
            ds_item: v.ds_item,
            ds_proporcao: v.ds_proporcao,
            id_dm004: v.id_dm004,
            id_dm004_sabor: v.id_dm004_sabor,
            id_dm089: v.id_dm089,
            is_checked: v.is_checked,
            qt_item_sabor: v.qt_item_sabor,
            qt_proporcao: 1 / selectedTaste.length,
          };
        });
        selectedTaste = tmpSelectedTaste;
        setTastes(selectedTaste);
      }
      setStorage('tastes', selectedTaste);
    },
    [tastes],
  );

  const clearTastes = () => {
    setTastes([]);
    setStorage('tastes', []);
  };

  return (
    <TastesContext.Provider
      value={{
        tastes,
        setTastes,
        selectedCheckbox,
        clearTastes,
      }}
    >
      {children}
    </TastesContext.Provider>
  );
};

export const useTastes = () => {
  const context = useContext(TastesContext);
  return context;
};
