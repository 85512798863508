import styled from 'styled-components';

interface PopupProps {
  hide?: boolean;
  maxWidth: number;
  maxHeight: number;
}

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const PopupInner = styled.div<PopupProps>`
  overflow-y: scroll;
  position: relative;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 20px black;

  min-width: 24rem;
  width: 100%;

  min-height: 20rem;
  max-height: 38rem;
  #button-exit {
    position: absolute;
    top: 1rem;
    right: 1rem;

    width: 3rem;
    height: 3rem;

    display: flex;
    justify-content: center;
    align-items: center;

    border: none;
    outline: none;
    background-color: transparent;
  }

  #buttonAdd {
    width: 60%;
    height: 2.5rem;
    margin-top: 1rem;

    background: var(--primary-color);

    border: none;
    border-radius: 8px;

    color: #fff;

    p {
      font-size: 1rem;
    }

    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  #align-children {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
