import { useState, useEffect } from 'react';
import { MdAdd, MdRemove } from 'react-icons/md';
import { useComplements } from 'hooks/useComplements';

import { ComplementsProps } from 'models';
import { toast } from 'react-toastify';
import { theme } from '../../App';

import { Container } from './styles';

interface CounterComplementsProps {
  complement: ComplementsProps;
  counter: number;
}

const CounterComplements: React.FC<CounterComplementsProps> = ({
  complement,
  counter,
}) => {
  const { clickCounters, complements } = useComplements();

  const [count, setCount] = useState(0);

  const complementIdDm026 = complement.ID_DM026;
  const complementIdDm202 = complement.ID_DM202;
  const handleAddCount = () => {
    const totalComplementsCounter = complements
      .filter(val => val.IN_SOLICITA_QUANTIDADE !== '')
      .filter(val => val.ID_DM202 === complementIdDm202)
      .reduce(
        (accum: number, curr: ComplementsProps) => accum + Number(curr.qtd),
        0,
      );

    if (complement.NR_GRUPO_QUANTIDADE_MAX > 0) {
      if (totalComplementsCounter + 1 > complement.NR_GRUPO_QUANTIDADE_MAX) {
        toast.warn(
          `Quantidade máxima de itens alcançada: ${complement.NR_GRUPO_QUANTIDADE_MAX}`,
        );
        return;
      }
    }

    if (
      complement.QT_GRUPO_QUANTIDADE_COMBO > 0 &&
      !(count === complement.QT_MAXIMA * counter) &&
      totalComplementsCounter < complement.QT_GRUPO_QUANTIDADE_COMBO
    ) {
      const newCount = count + 1;
      setCount(count + 1);
      clickCounters(complement, newCount);
    }

    if (
      complement.QT_GRUPO_QUANTIDADE_COMBO === 0 &&
      complement.QT_MAXIMA > 0 &&
      !(count === complement.QT_MAXIMA * counter)
    ) {
      const newCount = count + 1;
      setCount(count + 1);
      clickCounters(complement, newCount);
    }

    if (
      (complement.QT_GRUPO_QUANTIDADE_COMBO === 0 && !complement.QT_MAXIMA) ||
      complement.QT_MAXIMA === 0
    ) {
      const newCount = count + 1;
      setCount(count + 1);
      clickCounters(complement, newCount);
    }
  };

  const handleSubCount = () => {
    if (count > 0) {
      const newCount = count - 1;
      setCount(count - 1);
      clickCounters(complement, newCount);
    } else {
      setCount(0);
      clickCounters(complement, 0);
    }
  };

  useEffect(() => {
    if (count > complement.QT_MAXIMA * counter && complement.QT_MAXIMA > 0) {
      const newCount = complement.QT_MAXIMA * counter;
      setCount(complement.QT_MAXIMA * counter);
      clickCounters(complement, newCount);
    }
  }, [counter, complement.QT_MAXIMA, count, clickCounters, complement]);

  return (
    <Container>
      <button type="button" onClick={handleSubCount} aria-label="sub-counter">
        <MdRemove size={22} color={theme.palette.primary.main} />
      </button>
      <p>{count}</p>
      <button type="button" onClick={handleAddCount} aria-label="add-counter">
        <MdAdd size={22} color={theme.palette.primary.main} />
      </button>
    </Container>
  );
};

export default CounterComplements;
