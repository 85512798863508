import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { clearStorage } from 'services/storage';
import { useRoutes } from 'hooks/useRoutes';
import Modal from 'components/Modal';

import { FiChevronRight, FiCornerUpLeft } from 'react-icons/fi';
import { ImListNumbered } from 'react-icons/im';
import { theme } from '../../App';

import { Container, AnimationContainer, ContentCenter } from './styles';

const Changes: React.FC = () => {
  const [showModalExit, setShowModalExit] = useState(false);
  const { currentRoute, updateRoute } = useRoutes();
  const history = useHistory();
  return (
    <AnimationContainer>
      <Container>
        <ContentCenter>
          <div id="headerContent">
            <p>Opções</p>
          </div>
          <div id="listOptions">
            {/* Code to add new command to order */}
            {/* <button className="row" type="button">
              <Link to="new_command">
                <div>
                  <div>
                    <ImListNumbered size={20} />
                  </div>
                  <p>Adicionar comanda</p>
                </div>
              </Link>
              <FiChevronRight size={25} color={theme.palette.primary.main} />
            </button> */}

            <button
              className="row"
              type="button"
              onClick={() => setShowModalExit(true)}
            >
              <div>
                <div>
                  <FiCornerUpLeft size={20} />
                </div>
                <p>Sair</p>
              </div>
            </button>
          </div>
        </ContentCenter>

        <Modal
          visible={showModalExit}
          setVisible={setShowModalExit}
          maxHeight={30}
          maxWidth={30}
        >
          <p className="text-modal-cpf">Deseja sair ?</p>

          <div className="buttons-modal-cpf">
            <button
              type="button"
              className="button-filled"
              onClick={() => {
                clearStorage();
                setShowModalExit(false);
                updateRoute('/');
                history.push('/');
                window.location.reload();
              }}
            >
              Sim
            </button>
            <button
              type="button"
              className="button-outline"
              onClick={() => {
                setShowModalExit(false);
                updateRoute('/');
                history.push('/');
              }}
            >
              Não
            </button>
          </div>
        </Modal>
      </Container>
    </AnimationContainer>
  );
};

export default Changes;
