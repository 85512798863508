import styled from 'styled-components';

interface PopupProps {
  hide?: boolean;
  maxWidth: number;
  maxHeight: number;
}

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  /* background-color: #f8f8f8; */

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 10;
`;

export const PopupInner = styled.div<PopupProps>`
  position: relative;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;

  min-width: 24rem;
  width: ${props => `${props.maxWidth}vw`};

  min-height: 12.5rem;
  /* height: ${props => `${props.maxHeight}vh`}; */

  #button-exit {
    position: absolute;
    top: 1rem;
    right: 1rem;

    width: 3rem;
    height: 3rem;

    display: flex;
    justify-content: center;
    align-items: center;

    border: none;
    outline: none;
    background-color: transparent;
  }

  #align-children {
    flex: 1;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
