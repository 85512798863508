import { useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import SpecificApiLocalClient from 'services/api';
import { useInformationsClient } from 'hooks/useInformationsClient';
import { getStorage, asyncLocalStorage } from 'services/storage';

import { toast } from 'react-toastify';
import axios from 'axios';
import {
  AnimationContainer,
  Container,
  ContentCenter,
  ROButton,
} from './styles';

const LastOrders: React.FC = () => {
  const [arrayLastOrders, setArrayLastOrders] = useState([]);
  const [countdown, setCountdown] = useState(30);
  const [countdownState, setCountdownState] = useState(true);
  const [companyId, setCompanyId] = useState('');
  const [companyUrl, setCompanyUrl] = useState('');
  const [phone, setPhone] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState('');

  useEffect(() => {
    let timeout;
    if (countdown > 0) {
      timeout = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else {
      setCountdownState(true);
    }
    return () => clearTimeout(timeout);
  }, [countdown]);

  useEffect(() => {
    if (countdownState) {
      if (phone !== '' && companyUrl !== '' && companyId !== '') {
        const api = axios.create({
          baseURL: `${companyUrl}/datasnap/rest/Tdm_ServerMethods`,
          timeout: 30000,
          headers: {
            'Bypass-Tunnel-Reminder': '*',
          },
        });
        api
          .get(
            `/%22fprg_RetornarStatusPedidoDeliveryAA%22/${companyId}/0/${phone}`,
          )
          .then(answer => {
            setArrayLastOrders(answer.data.result[0]);
            setCountdown(30);
            setCountdownState(false);
          })
          .catch(error => {
            toast.error('Erro ao buscar pedidos...');
            console.log(error);
          });
      }
    }
  }, [companyId, companyUrl, countdownState, cpfCnpj, phone]);

  useEffect(() => {
    if (getStorage('clientInfo')) {
      asyncLocalStorage.getItem('clientInfo').then(result => {
        const clientInfo = JSON.parse(result);
        asyncLocalStorage.getItem('companyCode').then(resultCompanyID => {
          asyncLocalStorage.getItem('ngrok').then(resultCompanyUrl => {
            setCpfCnpj(clientInfo.NR_CNPJ);
            setPhone(clientInfo.NR_FONE);
            setCompanyId(resultCompanyID.replaceAll('"', ''));
            setCompanyUrl(resultCompanyUrl.replaceAll('"', ''));
          });
        });
      });
    }
  }, []);

  return (
    <AnimationContainer>
      <Container>
        <ContentCenter>
          <header>
            <h2>Últimos Pedidos ({countdown})</h2>
          </header>
          <main>
            <table>
              <thead>
                <tr>
                  <th>Pedido</th>
                  <th>Data</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {arrayLastOrders &&
                  arrayLastOrders.map((order, index) => (
                    <div style={{ width: '100%' }}>
                      <Typography style={{ width: '100%' }}>
                        <tr key={Number(index)}>
                          <td>{order.CD_PEDIDO}</td>
                          <td>{order.DT_SITUACAO}</td>
                          <td className="table-price">{order.IN_SITUACAO}</td>
                        </tr>
                      </Typography>
                    </div>
                  ))}
              </tbody>
            </table>
          </main>
        </ContentCenter>
      </Container>
    </AnimationContainer>
  );
};

export default LastOrders;
