/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  createContext,
  useState,
  ReactNode,
  useContext,
  useCallback,
} from 'react';
import { ComplementsProps } from 'models';
import { setStorage } from 'services/storage';

interface ComplementsProviderProps {
  children: ReactNode;
}

interface ComplementsContextData {
  complements: ComplementsProps[];
  setComplements: React.Dispatch<React.SetStateAction<ComplementsProps[]>>;
  selectCheckboxs: (checkbox: ComplementsProps, type: 'add' | 'rem') => void;
  clickCounters: (complement: ComplementsProps, count: number) => void;
  clearComplements: () => void;
}

const ComplementsContext = createContext<ComplementsContextData>(
  {} as ComplementsContextData,
);

export const ComplementsProvider = ({ children }: ComplementsProviderProps) => {
  const [complements, setComplements] = useState<ComplementsProps[]>([]);

  const selectCheckboxs = useCallback(
    (checkbox: ComplementsProps, type: 'add' | 'rem') => {
      let selectedComplement;
      // check if checkbox is set
      if (complements.some(index => index.DS_ITEM === checkbox.DS_ITEM)) {
        const filteredComplements = complements.filter(
          index => index.DS_ITEM !== checkbox.DS_ITEM,
        );
        selectedComplement = filteredComplements;
        setComplements(filteredComplements);
      } else {
        // add new complement from checkbox
        checkbox.cd_item_complemento = checkbox.CD_ITEM_COMPLEMENTO;
        checkbox.ds_item = checkbox.DS_ITEM;
        checkbox.id = checkbox.ID_DM026;
        checkbox.isCheckBox = true;
        checkbox.isChecked = true;
        checkbox.priceFormart = '';
        checkbox.remover = type !== 'add';
        checkbox.qtd = 1;
        checkbox.title = checkbox.DS_ITEM;
        checkbox.priceFormated = '';
        checkbox.subtotal = '';
        selectedComplement = [...complements, checkbox];
        setComplements([...complements, checkbox]);
      }

      setStorage('complements', selectedComplement);
    },
    [complements],
  );

  const clickCounters = useCallback(
    (complement: ComplementsProps, count: number) => {
      let clickedComplement;
      // check if the are value o complement
      if (count > 0) {
        // check if value exists
        if (complements.some(index => index.ID_DM026 === complement.ID_DM026)) {
          const complementExists = complements.find(
            complementFind => complementFind.ID_DM026 === complement.ID_DM026,
          );

          if (complementExists) {
            complementExists.qtd = count;
          }
          setComplements([...complements]);
          clickedComplement = [...complements];
        } else {
          // add new complement counter
          complement.cd_item_complemento = complement.CD_ITEM_COMPLEMENTO;
          complement.ds_item = complement.DS_ITEM;
          complement.id = complement.ID_DM026;
          complement.isCheckBox = true;
          complement.isChecked = true;
          complement.priceFormart = '';
          complement.remover = false;
          complement.qtd = count;
          complement.title = complement.DS_ITEM;
          complement.priceFormated = '';
          complement.subtotal = '';

          setComplements([...complements, complement]);
          clickedComplement = [...complements, complement];
        }
      } else {
        const filteredComplements = complements.filter(
          index => index.ID_DM026 !== complement.ID_DM026,
        );
        clickedComplement = filteredComplements;
        setComplements(filteredComplements);
      }

      setStorage('complements', clickedComplement);
    },
    [complements],
  );

  const clearComplements = () => {
    setComplements([]);
    setStorage('complements', []);
  };

  return (
    <ComplementsContext.Provider
      value={{
        complements,
        setComplements,
        selectCheckboxs,
        clickCounters,
        clearComplements,
      }}
    >
      {children}
    </ComplementsContext.Provider>
  );
};

export const useComplements = () => {
  const context = useContext(ComplementsContext);

  return context;
};
