import styled, { keyframes, css } from 'styled-components';
import Paper from '@material-ui/core/Paper';

interface isVisibleSearch {
  showSearch: boolean;
}

export const Container = styled.div`
  height: 92%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Main = styled.main<isVisibleSearch>`
  width: 100%;

  overflow-y: scroll;

  #search {
    width: 100%;
    height: 3.5rem;

    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;

    ${props =>
      // eslint-disable-next-line prettier/prettier
      props.showSearch === true
        ? css`
            z-index: 2;
          `
        : css`
            opacity: 0;
          `}

    div {
      background: #efefef;
      padding: 0.5rem 1rem;
      width: 100%;
      border-radius: 0.5rem;
      margin: 0 2rem;

      display: flex;
      justify-content: center;
      align-items: center;

      input {
        width: 100%;

        background: #efefef;
        padding: 0.33rem 1rem;
        margin-right: auto;
        border: none;
        font-size: 1.25rem;

        &::placeholder {
          font-size: 1.25rem;
          font-weight: 100;
        }
      }
    }
  }

  #content-modal-start {
    display: flex;
    flex-direction: column;

    h1 span {
      font-size: 1rem;
      font-weight: normal;
      align-items: center;
    }

    h1 {
      font-size: 2rem;
      font-weight: bold;
      color: var(--primary-color);
      text-align: center;
    }

    .row {
      display: flex;
      align-items: center;
      justify-content: space-around;

      margin: 1rem 0;

      span {
        color: var(--primary-color);
        font-size: 6rem;
        margin-left: 0.5rem;
      }
      p {
        font-size: 2rem;
      }
    }
    p {
      font-size: 1.3rem;
    }

    .proceed {
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      margin-top: 1rem;

      height: 2.5rem;

      font-size: 1.4rem;
    }

    #align-footer {
      align-items: center;
      display: inline-block;

      margin-top: 0rem;
      margin-bottom: 0.5rem;

      img {
        margin-left: 8rem;
        margin-right: 0rem;
        margin-top: 10px;
        width: 2rem;
        height: 2rem;
      }

      a {
        text-decoration: none;
        color: var(--primary-color);
        font-weight: bold;
        font-size: 1.3rem;
      }
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    #align-footer-delivery {
      align-items: center;
      display: inline-block;

      margin-top: 0rem;
      margin-bottom: 0.5rem;

      img {
        margin-left: 5rem;
        margin-right: 0rem;
        margin-top: 10px;
        width: 2rem;
        height: 2rem;
      }

      a {
        text-decoration: none;
        color: var(--primary-color);
        font-weight: bold;
        font-size: 1.3rem;
      }
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    #accept-location {
      margin: 1rem 0 2rem 0;
      padding: 1rem;

      font-size: 1rem;
      text-align: center;

      color: #9d9d9d;
      border: 2px solid rgba(241, 203, 89, 0.8);
      background-color: rgba(241, 203, 89, 0.1);
      border-radius: 0.5rem;

      & + p {
        color: #9d9d9d;
        margin-bottom: 1rem;
      }
    }
    .inputHomeCart {
      width: 100%;
      height: 2.5rem;
      margin-top: 0.5rem;
      padding: 0.5rem;
      border-radius: 8px;
      border: 3px solid var(--primary-color);
      background-color: var(--secondary-color);
      font-size: 1.3rem;
      color: var(--primary-color);
    }
    h3 {
      font-weight: 500;
      margin-top: 30px;
    }
  }
`;

export const ContainerTabs = styled(Paper)`
  width: 100%;
  box-shadow: 0px 4px 4px rgba(200, 200, 200, 0.5) !important;
`;

export const ContainerHeader = styled.div`
  #header_banner {
    width: 100%;
    display: flex;
    background: #fff;

    img {
      border-radius: 0.5rem;
      margin-right: 0.5rem;
    }

    p {
      font-size: 16px;
      font-weight: bold;
    }

    div {
      background: #fff;
      padding: 0.3rem 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const ContainerDeliveryModal = styled.div`
  #logo_modal {
    width: 120%;
    background: #fff;

    img {
      border-radius: 0.5rem;
    }

    p {
      font-size: 18px !important;
      font-weight: bold;
      margin-right: 0.5rem;
    }

    div {
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  #text_modal {
    width: 100%;
    background: #fff;
    display: inline-block;

    p {
      margin-left: 2rem;
      font-size: 18px !important;
      text-align: right !important;
    }

    .open_time {
      color: green;
      font-weight: bold;
      text-align: left !important;
    }

    .close_time {
      color: red;
      font-weight: bold;
      text-align: left !important;
    }

    div {
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  #text_info_modal {
    width: 100%;
    background: #fff;

    p {
      font-size: 16px !important;
      text-align: center !important;
    }

    .bold_text {
      font-weight: bold !important;
    }

    div {
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  #icons_info_modal {
    width: 100%;
    background: #fff;
    margin-top: 1rem;

    div {
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const ButtonSearch = styled.button`
  position: absolute;
  bottom: 125px;
  right: 1rem;
  border: none;
  background: #f47321;
  height: 45px;
  width: 45px;
  border-radius: 50%;

  label {
    cursor: pointer;
    color: #fff;
  }
  /* prettier-ignore */

  svg {
    color: #fff !important;
  }
`;

export const ButtonWhats = styled.button`
  position: absolute;
  bottom: 70px;
  right: 1rem;
  border: none;
  background: #fff;
  height: 45px;
  width: 45px;
  border-radius: 50%;

  label {
    cursor: pointer;
    color: red;
  }
  /* prettier-ignore */

  svg {
    color: #2BD366 !important;
  }
`;

export const ButtonDelivery = styled.button`
  background-color: transparent;
  border-color: transparent;
`;
