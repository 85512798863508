import styled from 'styled-components';

export const Container = styled.div`
  @media screen and (max-width: 1060px) {
    width: 90%;
    min-height: 7rem;
    max-height: 8rem;
  }

  @media screen and (min-width: 1060px) {
    width: 70%;
  }

  padding: 1rem;
  margin: 0 auto;

  background-image: linear-gradient(
    to right,
    #f6f0ed,
    #f7f1ed,
    #f7f1ed,
    #f8f2ed,
    #f8f3ed
  );

  border-radius: 4px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 4rem;
    height: 4rem;
    border-radius: 5%;
  }

  #informationsProduct {
    width: 100%;

    display: flex;
    flex-direction: column;
    padding: 0 1rem;

    gap: 1rem;

    #title-product {
      font-size: 1rem;
      font-weight: 400;
    }

    .text-modal {
      p {
        font-size: 1rem;
        font-weight: 300;
        color: var(--primary-color);
        text-align: start;
      }
    }

    .more-informations {
      border: none;
      background-color: transparent;
      width: fit-content;

      p {
        font-size: 1rem;
        font-weight: 300;
        color: var(--primary-color);
        text-align: start;
      }
    }

    #price-product {
      font-size: 1rem;
      font-weight: 300;
      color: var(--price-color);
    }
  }

  #actionsArea {
    height: 100%;
    max-height: 100%;
    min-width: 3rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    button {
      width: 1.25rem;
      height: 1.25rem;

      border: none;
      background: transparent;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    #qtde-product {
      font-size: 1rem;
    }
  }
`;
