import { useState, useEffect } from 'react';
import { MdAdd, MdRemove } from 'react-icons/md';
import { useTasteComplements } from 'hooks/useTasteComplements';

import { ComplementsProps } from 'models';
import { theme } from '../../App';

import { Container } from './styles';

interface CounterTasteComplementsProps {
  complement: ComplementsProps;
  counter: number;
}

const CounterTasteComplements: React.FC<CounterTasteComplementsProps> = ({
  complement,
  counter,
}) => {
  const { clickTasteCounters } = useTasteComplements();

  const [count, setCount] = useState(0);

  const handleAddCount = () => {
    if (!(count === complement.QT_MAXIMA * counter)) {
      const newCount = count + 1;
      setCount(count + 1);
      clickTasteCounters(complement, newCount);
    }
    if (!complement.QT_MAXIMA || complement.QT_MAXIMA === 0) {
      const newCount = count + 1;
      setCount(count + 1);
      clickTasteCounters(complement, newCount);
    }
  };

  const handleSubCount = () => {
    if (count > 0) {
      const newCount = count - 1;
      setCount(count - 1);
      clickTasteCounters(complement, newCount);
    } else {
      setCount(0);
      clickTasteCounters(complement, 0);
    }
  };

  useEffect(() => {
    if (count > complement.QT_MAXIMA * counter && complement.QT_MAXIMA > 0) {
      const newCount = complement.QT_MAXIMA * counter;
      setCount(complement.QT_MAXIMA * counter);
      clickTasteCounters(complement, newCount);
    }
  }, [counter, complement.QT_MAXIMA, count, clickTasteCounters, complement]);

  return (
    <Container>
      <button type="button" onClick={handleSubCount} aria-label="sub-counter">
        <MdRemove size={22} color={theme.palette.primary.main} />
      </button>
      <p>{count}</p>
      <button type="button" onClick={handleAddCount} aria-label="add-counter">
        <MdAdd size={22} color={theme.palette.primary.main} />
      </button>
    </Container>
  );
};

export default CounterTasteComplements;
