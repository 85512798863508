// eslint-disable-next-line import/no-extraneous-dependencies
import axios, { AxiosInstance } from 'axios';
import { useInformationsClient } from 'hooks/useInformationsClient';

const SpecificApiLocalClient = (): AxiosInstance => {
  const { companyData } = useInformationsClient();
  const api = axios.create({
    baseURL: `${companyData.ngrok}/datasnap/rest/Tdm_ServerMethods`,
    timeout: 30000,
    headers: {
      'Bypass-Tunnel-Reminder': '*',
    },
  });

  return api;
};

export default SpecificApiLocalClient;
