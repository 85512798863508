import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  border-top: 1px solid var(--line-color);
  position: fixed;
  bottom: 0px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 7px black;
  background: #fff;
`;

export const ButtonCart = styled(Link)`
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;

  text-decoration: none;

  button {
    min-width: 3rem;
    min-height: 2rem;
    position: relative;
    border: none;
    background-color: transparent;
  }
`;

export const NotificationCart = styled.div`
  width: 1rem;
  height: 1rem;
  background-color: var(--primary-color);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  right: 0.5rem;

  color: #fff;
`;

export const CardModal = styled.div`
  #modalContent {
    width: 100%;
    min-height: 3.75rem;
    max-height: 3.75rem;

    border-radius: 8px;

    display: grid;
    grid-template-columns: 45% 1fr;
    justify-items: center;
    align-items: center;

    .text-modal-waiter {
      font-size: 1.2rem;
    }

    .buttons-modal-waiter {
      width: 100%;

      display: flex;

      justify-content: space-between;
      align-items: end;

      margin-top: 6.25rem;

      button {
        width: 45%;
        height: 2.5rem;

        font-size: 1rem;
      }
    }
  }
`;
