import React from 'react';
import CardInformationsProduct from 'components/CarInformationsProduct';
import { ProductsProps } from 'models';
import { Container } from './styles';
import { useInformationsClient } from '../../hooks/useInformationsClient';

interface ProductHorizontalProps {
  product: ProductsProps;
}

const ProductHorizontal: React.FC<ProductHorizontalProps> = ({ product }) => {
  const { companyData } = useInformationsClient();
  const day = new Date().getDate();
  function ImageFood() {
    const url_product_img = `https://datamais.com.br/img-autoatendimento/${companyData.companyCode}/I${product.ID_DM004}.jpg?${day}`;
    return <img src={url_product_img} alt={product.DS_ITEM} />;
  }

  return (
    <Container>
      <CardInformationsProduct height={100} product={product} />
      <ImageFood />
    </Container>
  );
};

export default ProductHorizontal;
