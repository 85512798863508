/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createContext, ReactNode, useContext, useState } from 'react';

interface DateLoginProviderProps {
  children: ReactNode;
}

interface DateLoginContextData {
  userLoginData: {
    day: string;
    hour: string;
  };
  updateUserLoginData: (currentDay: Date, currentHour: string) => void;
}

const DateLoginContext = createContext<DateLoginContextData>(
  {} as DateLoginContextData,
);

export const DateLoginProvider = ({ children }: DateLoginProviderProps) => {
  const [userLoginData, setUserLoginData] = useState({
    day: '',
    hour: '',
  });

  const updateUserLoginData = (currentDay: Date, currentHour: string) => {
    const formatedDay = Intl.DateTimeFormat('pt-BR').format(currentDay);

    setUserLoginData({
      day: formatedDay,
      hour: currentHour,
    });
  };

  return (
    <DateLoginContext.Provider value={{ userLoginData, updateUserLoginData }}>
      {children}
    </DateLoginContext.Provider>
  );
};

export const useDateLogin = () => {
  const context = useContext(DateLoginContext);

  return context;
};
