import { ComplementsProps } from 'models';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 7.5rem);
  max-height: calc(100vh - 7.5rem);

  overflow-y: scroll;

  padding: 1.25rem;

  display: flex;

  position: relative;

  // UM DEBAIXO DO OUTRO
  @media screen and (max-width: 700px) {
    flex-direction: column;

    #container-image-product {
      width: 100%;
      height: 50%;

      display: flex;
      justify-content: center;

      img {
        width: 250;
        height: 250px;
        border-radius: 1rem;
      }
    }
  }

  // UM DO LADO DO OUTRO
  @media screen and (min-width: 700px) {
    flex-direction: row;

    #container-image-product {
      width: 50%;

      display: flex;
      justify-content: center;
      align-items: center;

      img {
        position: fixed;
        width: 300px;
        height: 300px;
        border-radius: 1rem;
      }
    }
  }
`;

export const ContainerFooterAdd = styled.div`
  height: 4.5rem;
  width: 100%;
  background: #fff;

  border: 1px solid var(--line-color);

  z-index: 2;
  position: fixed;
  bottom: 0;

  display: flex;
  justify-content: space-around;
  align-items: center;

  #buttonAdd {
    width: 60%;
    height: 2.5rem;

    background: var(--primary-color);

    border: none;
    border-radius: 8px;

    color: #fff;

    p {
      font-size: 1rem;
    }

    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`;

interface ContainerContentProductProps {
  hasComplements: ComplementsProps[];
}

export const ContainerContentProduct = styled.div<ContainerContentProductProps>`
  // UM DEBAIXO DO OUTRO
  @media screen and (max-width: 700px) {
    width: 100%;

    margin-top: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;

    #comentary {
      p {
        margin: 1.25rem 0 0.5rem;
      }

      textarea {
        width: 100%;
        padding: 1rem;
        border: 1px solid var(--line-color);
        border-radius: 8px;

        color: #9d9d9d;
        font-size: 1rem;

        resize: none;

        &::placeholder {
          color: #9d9d9d;
          font-size: 1rem;
        }

        &:focus {
          border: 2px solid var(--primary-color);
        }
      }
    }
  }

  // UM DO LADO DO OUTRO
  @media screen and (min-width: 700px) {
    width: 50%;
    height: 100%;

    margin-bottom: 1.25rem;

    #comentary {
      p {
        margin: 1.25rem 0 0.5rem;
      }

      textarea {
        width: 100%;
        padding: 1em;
        border: 1px solid var(--line-color);
        border-radius: 0.5rem;

        color: #9d9d9d;
        font-size: 1rem;

        resize: none;

        &::placeholder {
          color: #9d9d9d;
          font-size: 1rem;
        }

        &:focus {
          border: 2px solid var(--primary-color);
        }
      }
    }
  }
`;

export const ContainerCounter = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  width: 25%;
  height: 2.5rem;

  border: 1px solid var(--line-color);
  border-radius: 8px;

  button {
    border: none;
    background: #fff;

    color: var(--primary-color);
  }

  p {
    font-size: 1.5rem;
  }
`;

export const ContainerLoading = styled.div`
  height: calc(100vh - 7.5rem);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--primary-color);
    text-align: center;
  }
`;
