export const formatCurrencyBRL = (value: number): string => {
  const formatter = new Intl.NumberFormat([], {
    style: 'currency',
    currency: 'BRL',
  });
  if (value !== undefined && value > 0) {
    return formatter.format(value).toString().replace('.', ',');
  }
  return formatter.format(0).toString().replace('.', ',');
};

export const maskBRL = (value: string): string => {
  const firstReplace = value.replace(/\D/g, '');
  const maskFinal = firstReplace.replace(/(\d{0})(\d{1,2})$/, '$1,$2');
  return `R$ ${maskFinal}`;
};
