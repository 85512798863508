import styled from 'styled-components';
import { ThemeOptions } from '@material-ui/core/styles/createTheme';

interface StyledSendReceiptToWhatsappProps {
  theme: ThemeOptions;
}

export const StyledSendReceiptToWhatsapp = styled.div<StyledSendReceiptToWhatsappProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;

  .whatsapp-button {
    border: none;
    border-radius: 8px;
    width: 100%;
    padding: 1rem;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 12px;
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.secondary.main};
  }
`;
