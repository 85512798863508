import React, { useRef } from 'react';
import { AddressCardProps } from 'models';
import { FiTrash2 } from 'react-icons/fi';
import { Container } from './styles';

interface ProductCartProps {
  props: AddressCardProps;
  deleteAddress(id: string, desc: string): void;
  clickAddress(id: string): void;
}

const AddressCard: React.FC<ProductCartProps> = ({
  props,
  deleteAddress,
  clickAddress,
}) => {
  const { numeroDelivery, neighbor, index, descAddress, street, city, type } =
    props;
  const buttonRef = useRef(null);
  return (
    <>
      {city && (
        <Container>
          {type === 'address' ? (
            <>
              <div id="informationsAddress">
                <button
                  id="title-address"
                  type="button"
                  onClick={e => clickAddress(index)}
                  ref={buttonRef}
                >
                  {descAddress}
                  <span>
                    <br />
                    {Array.isArray(city) && city[0] && city[0].NM_CIDADE
                      ? city[0].NM_CIDADE.toUpperCase()
                      : city}
                    <br />
                    {Array.isArray(neighbor) &&
                    neighbor[0] &&
                    neighbor[0].NM_BAIRRO
                      ? neighbor[0].NM_BAIRRO.toUpperCase()
                      : neighbor}
                    <br />
                    {Array.isArray(street) &&
                    street[0] &&
                    street[0].DS_LOCALIZACAO
                      ? street[0].DS_LOCALIZACAO.toUpperCase()
                      : street}
                  </span>
                </button>
              </div>
              <div id="actionsArea">
                <button
                  type="button"
                  onClick={() => deleteAddress(index, descAddress)}
                  aria-label="remove-product-button"
                >
                  <FiTrash2 size={20} color="#f00" aria-hidden="true" />
                </button>
              </div>
              <br />
            </>
          ) : (
            <>
              <div id="informationsAddress">
                <button
                  id="title-address"
                  type="button"
                  onClick={e => clickAddress(index)}
                  ref={buttonRef}
                >
                  {descAddress}
                </button>
              </div>
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default AddressCard;
