import styled, { keyframes } from 'styled-components';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  height: calc(100vh - 7.5rem);
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--secondary-color);
`;

export const ContentCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // PEQUENO
  @media screen and (max-width: 1060px) {
    width: 100%;
    height: 100%;
    background-color: #fff;
  }

  // GRANDE
  @media screen and (min-width: 1060px) {
    width: 50%;
    height: 95%;
    max-height: 95%;

    box-shadow: 0px 0px 5px 2px rgba(200, 200, 200, 0.5);
    border: 1px solid var(--line-color);
    border-radius: 8px;

    background-color: #fff;
  }

  #headerContent {
    width: 100%;
    min-height: 3.75rem;
    max-height: 3.75rem;

    border-bottom: 0.5px solid var(--line-color);
    border-radius: 8px;

    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: 1.25rem;
      /* color: var(--primary-color); */
    }
  }
  .inputCommand {
    font-size: 1.25rem;
    width: 80%;
    border: none;
    border-radius: 1rem;
    text-align:center;
    height: 40px;
    background: #efefef;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  #divCommand {
    /* min-height: calc(100% - 7.5rem);
    max-height: calc(100% - 7.5rem); */

    padding: 1rem 0;

    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

    #title-modal {
      font-size: 1.25rem;
      font-weight: 100;
    }

    #description-modal {
      font-size: 1rem;
      color: #9d9d9d;
      margin-top: 1.75rem;
    }
  }

  #footerContent {
    width: 100%;
    min-height: 3.75rem;
    max-height: 3.75rem;

    border-top: 0.5px solid var(--line-color);
    border-radius: 8px;

    display: grid;
    justify-items: center;
    align-items: center;

    button.button-footer {
      width: 90%;
      height: 2rem;

      display: flex;
      justify-content: space-around;
      align-items: center;

      font-size: 1rem;
    }
  }

  .text-modal {
    font-size: 1.25rem;
    font-weight: 100;
  }

  .buttons-modal {
    width: 100%;

    display: flex;

    justify-content: space-between;
    align-items: end;

    margin-top: 6.25rem;

    button {
      width: 45%;
      height: 2.5rem;

      font-size: 1rem;
    }
  }
`;
