import { dateUnnixTime } from 'utils/dateUnixtime';
import { ProductsProps } from 'models';

const checkPromotionTime = (
  now: Date,
  starting_time: string,
  ending_time: string,
): boolean => {
  const now_unix = dateUnnixTime(now);
  const [year, month, day] = now.toISOString().substring(0, 10).split('-');
  if (starting_time !== '' && ending_time !== '') {
    const starting = dateUnnixTime(
      new Date(`${year}-${month}-${day} ${starting_time}`),
    );
    const ending = dateUnnixTime(
      new Date(`${year}-${month}-${day} ${ending_time}`),
    );
    if (now_unix >= starting && now_unix <= ending) {
      return true;
    }
    return false;
  }
  return true;
};

export const checkPromotion = (
  product: ProductsProps,
): [boolean, boolean, number] => {
  let sale_promotion = false;
  let sale_amount = false;
  let promotion_value = 0;

  const calcValue = (item: ProductsProps) => {
    if (item.VL_UNITARIO_PROMOCAO > 0) {
      promotion_value = item.VL_UNITARIO_PROMOCAO;
    } else if (Number(item.PR_DESCONTO) > 0) {
      promotion_value =
        item.VL_VENDA - item.VL_VENDA * (Number(item.PR_DESCONTO) / 100);
    } else if (item.VL_DESCONTO > 0) {
      promotion_value = item.VL_VENDA - item.VL_DESCONTO;
    }
  };

  if (product.ID_DM206 !== 0) {
    if (Number(product.QT_DISPONIVEL) > 0) {
      const available_quantity =
        Number(product.QT_DISPONIVEL) - Number(product.QT_CONSUMO);
      if (available_quantity > 0) {
        sale_amount = true;
      }
    } else {
      sale_amount = true;
    }

    const now = new Date();
    if (
      product.DT_PERIODO_DE !== '' &&
      product.DT_PERIODO_ATE !== '' &&
      product.DT_PERIODO_DE !== undefined &&
      product.DT_PERIODO_ATE !== undefined
    ) {
      const tmp_date_from = product.DT_PERIODO_DE.split(' ')[0].split('/');
      const tmp_date_to = product.DT_PERIODO_ATE.split(' ')[0].split('/');
      const date_from = new Date(
        `${tmp_date_from[1]}/${tmp_date_from[0]}/${tmp_date_from[2]}`,
      );
      const date_to = new Date(
        `${tmp_date_to[1]}/${tmp_date_to[0]}/${tmp_date_to[2]}`,
      );
      if (
        product.HR_VALIDADE1_DE !== '' &&
        product.HR_VALIDADE1_ATE !== '' &&
        product.HR_VALIDADE2_DE !== undefined &&
        product.HR_VALIDADE2_ATE !== undefined
      ) {
        if (now > date_from && now < date_to) {
          if (
            product.VL_UNITARIO_PROMOCAO > 0 ||
            Number(product.PR_DESCONTO) > 0 ||
            product.VL_DESCONTO > 0
          ) {
            if (
              checkPromotionTime(
                now,
                product.HR_VALIDADE1_DE,
                product.HR_VALIDADE1_ATE,
              ) ||
              checkPromotionTime(
                now,
                product.HR_VALIDADE2_DE,
                product.HR_VALIDADE2_ATE,
              )
            ) {
              sale_promotion = true;
              calcValue(product);
            }
          }
        }
      } else {
        sale_promotion = true;
        calcValue(product);
      }
    } else if (
      checkPromotionTime(
        now,
        product.HR_VALIDADE1_DE,
        product.HR_VALIDADE1_ATE,
      ) ||
      checkPromotionTime(now, product.HR_VALIDADE2_DE, product.HR_VALIDADE2_ATE)
    ) {
      sale_promotion = true;
      calcValue(product);
    }
  }
  return [sale_promotion, sale_amount, promotion_value];
};
