import React from 'react';

import { formatCurrencyBRL } from 'utils/formatCurrencyBRL';

import { ProductsProps } from 'models';

import { Container } from './styles';

interface InformationsProductProps {
  height: number;
  product: ProductsProps;
  addCart?: boolean | true;
  promotionalPrice?: number | 0;
}

const InformationsProduct: React.FC<InformationsProductProps> = ({
  height,
  product,
  addCart,
  promotionalPrice,
}) => {
  return (
    <Container height={height}>
      <p className="title-product">{product.DS_ITEM}</p>
      <p className="description-product">{product.DS_DESCRICAO_PRODUTO}</p>
      {addCart ? (
        <>
          {promotionalPrice !== 0 ? (
            <>
              <p className="value-product">
                <s className="old-product-value">
                  {formatCurrencyBRL(Number(product.VL_VENDA))}
                </s>
                &nbsp;&nbsp;
                <span className="promotion-value">
                  {formatCurrencyBRL(promotionalPrice)}
                </span>
              </p>
            </>
          ) : (
            <>
              <p className="value-product">
                {product.QT_SABOR > 0 ? (
                  <></>
                ) : (
                  formatCurrencyBRL(Number(product.VL_VENDA))
                )}
              </p>
            </>
          )}
        </>
      ) : (
        <>
          <p className="value-product">Indisponível!</p>
        </>
      )}
    </Container>
  );
};

export default InformationsProduct;
