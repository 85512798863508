import styled, { css } from 'styled-components';

interface InputProps {
  type: 'cpf' | 'tel' | 'number';
}

export const Container = styled.div<InputProps>`
  width: 100%;

  ${props =>
    props.type === 'cpf' &&
    css`
      margin-top: 2.5rem;
    `}

  label {
    font-size: 1rem;
    font-weight: 400;

    color: #9d9d9d;
  }

  input {
    width: 100%;
    height: 2.5rem;

    margin-top: 0.5rem;
    padding: 0.5rem;

    border-radius: 8px;
    border: 1px solid var(--primary-color);
    background-color: var(--secondary-color);

    font-size: 1.3rem;
    color: var(--primary-color);

    &::placeholder {
      font-size: 1rem;
      color: var(--primary-color);
    }

    &:focus {
      border-width: 3px;
    }
  }
`;
