import React from 'react';
import { Link } from 'react-router-dom';

import ProductHorizontal from 'components/ProductHorizontal';
import { ProductsProps } from 'models/index';
import { Container } from './styles';

interface CategoryProps {
  title: string;
  products: Array<ProductsProps>;
  search: boolean;
}

const Category: React.FC<CategoryProps> = ({ title, products, search }) => {
  return (
    <>
      {products.length > 0 && (
        <Container id={title} showSearch={search}>
          <p className="title">{title}</p>
          <div className="grid-items">
            {products.map(product => {
              return (
                <Link
                  to={`/products/${product.ID_DM004}`}
                  style={{ textDecoration: 'none' }}
                  key={product.CD_ITEM}
                >
                  <ProductHorizontal product={product} />
                </Link>
              );
            })}
          </div>
        </Container>
      )}
    </>
  );
};

export default Category;
