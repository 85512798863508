/* eslint-disable no-nested-ternary */
import React, { useImperativeHandle, useState } from 'react';

import { useInformationsClient } from 'hooks/useInformationsClient';
import { Container } from './styles';

interface InputProps {
  type: 'cpf' | 'tel' | 'number';
}

const Input = React.forwardRef(({ type }: InputProps, ref) => {
  const [valueInput, setValueInput] = useState('');
  const { companyData } = useInformationsClient();
  useImperativeHandle(
    ref,
    () => ({
      getValue: () => {
        return valueInput;
      },
    }),
    [valueInput],
  );

  const maskPhone = (text: string) => {
    const firstReplaced = text.replace(/\D/g, '');
    const secondReplaced = firstReplaced.replace(/^(\d{2})(\d)/g, '($1) $2');
    const maskedPhoneValue = secondReplaced.replace(/(\d)(\d{4})$/, '$1-$2');
    return maskedPhoneValue;
  };

  const maskCPF = (cpf: string) => {
    const firstReplace = cpf.replace(/\D/g, '');
    const secondReplace = firstReplace.replace(/(\d{3})(\d)/, '$1.$2');
    const thirdReplace = secondReplace.replace(/(\d{3})(\d)/, '$1.$2');
    const maskFinal = thirdReplace.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    return maskFinal;
  };

  const maskNumber = (text: string) => {
    return text.replace(/[^\d]/g, '');
  };

  return type === 'cpf' ? (
    <Container type={type}>
      <label htmlFor="input-cpf">CPF</label>
      <input
        type="numeric"
        id="input-cpf"
        placeholder="999.999.999-99"
        onChange={e => setValueInput(maskCPF(e.target.value))}
        value={valueInput}
        maxLength={14}
      />
    </Container>
  ) : type === 'tel' ? (
    <Container type={type}>
      <input
        type="numeric"
        id="input-telefone"
        placeholder="(__) ____-____"
        onChange={e => setValueInput(maskPhone(e.target.value))}
        value={valueInput}
        maxLength={15}
      />
    </Container>
  ) : (
    <Container type={type}>
      <input
        type="numeric"
        id="input-number"
        placeholder="_____"
        onChange={e => setValueInput(maskNumber(e.target.value))}
        value={valueInput}
        maxLength={5}
      />
    </Container>
  );
});

export default Input;
