import styled from 'styled-components';
import { ThemeOptions } from '@material-ui/core/styles/createTheme';

interface ContainerProps {
  theme: ThemeOptions;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  border-radius: 8px;
  border: 1px solid;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

  .pix-line {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    h2 {
      max-width: 17ch;
      word-wrap: break-word;
      font-size: 1.2rem;
      color: ${props => props.theme.palette.primary.main};
    }
  }

  .beneficiary-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 0.5;
  }
  .beneficiary-description {
    font-size: 0.5rem;
    text-transform: capitalize;
    color: ${props => props.theme.palette.primary.main};
  }
  .beneficiary-address {
    font-size: 0.25rem;
    color: ${props => props.theme.palette.primary.main};
  }
  .copy-button {
    background: none;
    border: none;
    border-radius: 8px;
    padding: 0.5rem;
    cursor: pointer;
    outline: 1px solid ${props => props.theme.palette.primary.main};

    /* &:hover {
      background: #f0f0f0;
    } */

    &:active {
      background: ${props => props.theme.palette.primary.main};
    }

    &:focus {
      outline: 1px solid ${props => props.theme.palette.primary.main};
    }
  }

  .copy-message {
    position: absolute;
    top: 2px;
    right: 50% - 50%;
    background: ${props => props.theme.palette.success.main};
    padding-block: 0.5rem;
    padding-inline: 1rem;
    border-radius: 8px;
    font-size: 0.8rem;
    color: ${props => props.theme.palette.success.contrastText};
  }

  h2 {
    font-size: 20px;
    color: ${props => props.theme.palette.primary.main};
  }
  p {
    font-size: 12px;
    color: ${props => props.theme.palette.primary.main};
  }
`;
