/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { useInformationsClient } from 'hooks/useInformationsClient';

interface Location {
  loaded: boolean;
  coordinates: {
    lat: string;
    lng: string;
  };
}

const useLocation = (): Location => {
  const [location, setLocation] = useState<Location>({
    loaded: false,
    coordinates: { lat: '', lng: '' },
  });

  const onSuccess = (locationParam: any) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: locationParam.coords.latitude,
        lng: locationParam.coords.longitude,
      },
    });
  };

  const onError = (error: any) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: error,
        lng: error,
      },
    });
  };

  const { companyData } = useInformationsClient();

  useEffect(() => {
    if (companyData.setLocation === 'S') {
      if (!('geolocation' in navigator)) {
        onError({
          code: 0,
          message: 'Geolocation not supported',
        });
      }
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    }
  }, [companyData.setLocation]);

  return location;
};

export default useLocation;
