import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 0.5rem;

  @media screen and (max-width: 1060px) {
    #title-complement {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 0.8rem;

      width: 100%;
      background-color: #cdc8c8;

      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    ul {
      list-style: none;

      li {
        font-weight: 100;

        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.5rem;

        border: 0.5px solid var(--line-color);

        .complement-value {
          color: var(--primary-color);
        }
      }
    }
  }

  // UM DO LADO DO OUTRO
  @media screen and (min-width: 1060px) {
    display: flex;
    flex-direction: column;

    #title-complement {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 1rem;

      width: 100%;
      background-color: #cdc8c8;

      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    ul {
      list-style: none;

      li {
        font-weight: 100;

        display: flex;
        justify-content: space-between;
        align-items: center;

        border: 0.5px solid var(--line-color);

        .complement-value {
          color: var(--primary-color);
        }
      }
    }
  }
`;

export const Description = styled.div`
  width: 100%;
`;
