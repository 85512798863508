import styled, { keyframes } from 'styled-components';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  height: calc(100vh - 7.5rem);
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--secondary-color);
`;

export const ContentCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: #fff;

  // PEQUENO
  @media screen and (max-width: 1060px) {
    width: 100%;
    height: 100%;
  }

  // GRANDE
  @media screen and (min-width: 1060px) {
    width: 50%;
    height: 95%;
    max-height: 95%;

    box-shadow: 0px 0px 5px 2px rgba(200, 200, 200, 0.5);
    border: 1px solid var(--line-color);
    border-radius: 8px;
  }

  header {
    width: 100%;
    min-height: 7.5rem;
    max-height: 7.5rem;

    border-bottom: 0.5px solid var(--line-color);
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  main {
    min-height: calc(100% - 7.5rem);
    max-height: calc(100% - 7.5rem);

    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    table {
      width: 100%;

      tr {
        display: flex;
        justify-content: space-between;

        border-bottom: 1px solid var(--primary-color);

        th {
          min-width: 25%;
          max-width: 25%;
          text-align: center;

          margin: 0.5rem 0;

          color: var(--primary-color);
        }

        td {
          min-width: 25%;
          max-width: 25%;

          margin: 0.5rem 0;

          font-weight: 100;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          li {
            text-align: center;
            list-style: none;
          }
        }

        td.table-price {
          font-weight: 400;
          color: var(--price-color);
        }
      }
    }
  }

  .itens-order {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: -10px;
    margin-top: -10px;
    p {
      padding-left: 30px;
      margin-bottom: 5px;
    }
  }
`;

export const ROButton = styled.button`
  margin-top: 14px;
  background: var(--primary-color);
  color: #fff;
  padding: 7px;
  font-size: 17px;
  text-align: center;
  border-radius: 5px;
  width: 100%;
  border: none;
`;
