/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getStorage = (key: string): string | null => {
  return localStorage.getItem(key);
};

export const removeStorage = (key: string): string => {
  localStorage.removeItem(key);
  return `Removido ${key}`;
};

export const setStorage = (key: string, value: any, json = true): string => {
  const newValue = json ? JSON.stringify(value) : value;
  localStorage.setItem(key, newValue);

  return `Adicionado ${key}`;
};

export const clearStorage = (): void => {
  return localStorage.clear();
};

export const asyncLocalStorage = {
  async setItem(key: string, value: string) {
    return localStorage.setItem(key, value);
  },
  async getItem(key: string) {
    return localStorage.getItem(key);
  },
};
